import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import moment from "moment-timezone";
import { IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DATE_PICKER_DATA } from "./consts";

import ChevronLeftIcon from "../../../shared/icon/chevron-left.svg";
import ChevronRightIcon from "../../../shared/icon/chevron-right.svg";

import "./DataAvailabilitySingleDatePicker.css";
import { getTremorSeverityAllStreamsParams } from "src/providers/streams/runeStreamsUtils";
import { useDataProvider } from "react-admin";
import { SEC_PER_DAY } from "src/constants";

interface DataAvailabilitySingleDatePickerProps {
  id: string;

  patientId: string;
  date: moment.Moment | null;
  focused: boolean;

  setDate: (date: moment.Moment | null) => void;
  setFocus: (focused: boolean) => void;
}

const DataAvailabilitySingleDatePicker = (
  props: DataAvailabilitySingleDatePickerProps
) => {
  const dataProvider = useDataProvider();

  const [availabilityData, setAvailabilityData] = useState<{
    [key: number]: string[];
  }>([]);

  useEffect(() => {
    setAvailabilityData([]);
    // TODO: Address timezone management as part of SW-3563
    const timeZone = moment.tz.guess();

    //get moment object with browser time zone for now
    const endDateMoment = moment().tz(timeZone);

    // TODO: Refresh the calendar availability dynamically instead of only looking at the last 6 months: SW-3699
    const startDateMoment = endDateMoment.clone().subtract(6, "months");

    // First, we get one representaive set of streams (tremor - severity:all) across all devices
    Promise.all([
      dataProvider.getStreamIds(
        getTremorSeverityAllStreamsParams(props.patientId)
      )
    ]).then(([tremorStreamsMetadata]) => {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const streamsMetadataAvailabilityPromises: Promise<any>[] = [];

      tremorStreamsMetadata.data.forEach((streamMetadata: any) => {
        if (
          startDateMoment.unix() < streamMetadata.maxTime &&
          endDateMoment.unix() > streamMetadata.minTime
        ) {
          streamsMetadataAvailabilityPromises.push(
            dataProvider.getStreamAvailability(streamMetadata.id, {
              start_time: startDateMoment.unix(),
              end_time: endDateMoment.unix(),
              resolution: SEC_PER_DAY,
              // TODO: SW-3563: Timezone are currently not handled correctly.
              // It should be taking into account the timezone of the user instead of being hardcoded.
              // A full revamp/revisit/refactor of the timezone management is expected
              timezone_name: timeZone,
              batch_operation: "any",
              format: "json"
            })
          );
        }
      });
      return Promise.all(streamsMetadataAvailabilityPromises).then(
        (streamsData) => {
          const streamsAvailabilityIndexedByTimestamp: {
            [key: number]: string[];
          } = {};
          streamsData.forEach((streamDataResult: any) => {
            const streamAvailability = streamDataResult.json.data;

            for (let i = 0; i < streamDataResult.json.cardinality; i++) {
              // The availability endpoint is not respecting the timezone_name parameter
              // We need to convert the timestamp from UTC to the specified timezone

              const timestamp = moment
                .unix(streamAvailability.time[i])
                .add(1, "days")
                .tz(timeZone)
                .set({ hour: 0, minute: 0, second: 0 })
                .unix();

              // If the timestamp does not exist yet in the object, we initialize it with an empty array
              if (!(timestamp in streamsAvailabilityIndexedByTimestamp)) {
                streamsAvailabilityIndexedByTimestamp[timestamp] = [];
              }

              if (streamAvailability.availability[i] === 1) {
                streamsAvailabilityIndexedByTimestamp[timestamp] = [
                  "tremor_dyskinesia"
                ];
              }
            }
          });
          setAvailabilityData(streamsAvailabilityIndexedByTimestamp);
        }
      );
    });
  }, []);

  const renderDayContents = (day: moment.Moment) => {
    const unixDate = day.startOf("day").unix();
    const dataForDate = availabilityData ? availabilityData[unixDate] : [];
    const Date = () => <div>{day.format("D")}</div>;

    // TODO: Right now, we only populate tremor_dyskinesia, but other data types should be added as well
    const dataTypes = ["tremor_dyskinesia", "sleep", "mobility"];

    if (!dataForDate) {
      return (
        <>
          <Date />
          {dataTypes.map((dataType) => (
            <div
              key={dataType}
              className="dayContents dataAvailabilityDateRangePickerNoData"
            ></div>
          ))}
        </>
      );
    }

    const classes: { [key: string]: any } = {};

    dataTypes.forEach((dataType) => {
      classes[dataType] = "dataAvailabilityDateRangePickerNoData";
      if (dataForDate.includes(dataType.toLowerCase())) {
        const classNameSuffix =
          DATE_PICKER_DATA[dataType as keyof typeof DATE_PICKER_DATA]
            .classNameSuffix;
        classes[dataType] = `dataAvailabilityDateRangePicker${classNameSuffix}`;
      }
    });

    return (
      <>
        <Date />
        {dataTypes.map((dataType) => (
          <div
            key={dataType}
            className={`dayContents ${classes[dataType]}`}
          ></div>
        ))}
      </>
    );
  };

  const isOutsideRange = (day: moment.Moment) => {
    return day.isAfter(moment());
  };

  function onLeftArrowClick(): void {
    props.setDate(moment(props.date).subtract(1, "days"));
  }

  function onRightArrowClick(): void {
    props.setDate(moment(props.date).add(1, "days"));
  }

  return (
    <div
      className="strivestudy-single-date-picker"
      data-cy="strivestudy-single-date-picker"
    >
      <div style={{ marginRight: 8 }}>
        <Typography component="span" variant="body2">
          Selected date:
        </Typography>
      </div>

      <IconButton size="small" onClick={onLeftArrowClick} title="Previous day">
        <img src={ChevronLeftIcon} height={25} width={25} />
      </IconButton>

      <SingleDatePicker
        id="date_input"
        date={props.date}
        onDateChange={(date) => props.setDate(date)}
        focused={props.focused}
        onFocusChange={({ focused }) => props.setFocus(focused)}
        numberOfMonths={3}
        displayFormat="DD-MMM-YYYY"
        showClearDate={false}
        isOutsideRange={isOutsideRange}
        small
        noBorder
        renderDayContents={renderDayContents}
        hideKeyboardShortcutsPanel={true}
      />

      <IconButton
        size="small"
        onClick={onRightArrowClick}
        title="Next day"
        disabled={isOutsideRange(moment(props.date).add(1, "days"))}
      >
        <img src={ChevronRightIcon} height={25} width={25} />
      </IconButton>
    </div>
  );
};

export default DataAvailabilitySingleDatePicker;
