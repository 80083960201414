import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import { QueryTypes } from "../../constants";
import { clientSideSorting } from "./clientSideSorting";
import { ProjectDisplayConfig } from "src/components/resources/project/ProjectShow/ProjectDisplayConfigurationDialog";
import { PatientMetricData } from "./buildQueryProjectPatient";
import { DATA_AVAILABILITY_THRESHOLD_DAYS, SEC_PER_DAY } from "../../constants";
import { isDataOutdated } from "./utilities";

interface BuildQueryProjectParams {
  id: string;
  data: {
    id: string;
    title: string;
    description: string;
    type: string;
    startedAt: Date;
    config?: ProjectDisplayConfig;
  };
  meta: {
    patientListV2: boolean;
    metricCategories: string[];
  };
}

interface Project {
  id: string;
  title: string;
  description: string;
  status: string;
  type: string;
  startedAt: number;
  config?: ProjectDisplayConfig;
  createdAt: number;
  organizationId: string;
  referralCode: {
    code: string;
    appMode: {
      id: number;
      displayName: string;
    };
  };
  updatedAt: number;
  createdBy: string;
  updatedBy: string;
  projectPatientList: {
    aggregatedMetrics: {
      totalPatientCount: number;
    };
  };
}

const buildQueryProject = (
  fetchType: string,
  params: BuildQueryProjectParams
): BuildQueryResult => {
  switch (fetchType) {
    case QueryTypes.CREATE:
      return {
        query: gql`
          mutation ($input: CreateProjectInput!) {
            createProject(input: $input) {
              project {
                id
                title
                description
                status
                type
                startedAt
                createdAt
                updatedAt
                createdBy
                updatedBy
                dataExportEnabled
                config
                projectPatientList {
                  aggregatedMetrics {
                    totalPatientCount
                    patientsWithoutSymptomDataCount
                    patientsWithoutHeartRateDataCount
                    patientsWithoutHeartRateAndSymptomDataCount
                  }
                }
              }
            }
          }
        `,
        variables: {
          input: {
            title: params.data.title,
            description: params.data.description,
            type: params.data.type,
            startedAt: params.data.startedAt.getTime() / 1000
          }
        },
        parseResponse: (response) => {
          const data = response.data.createProject.project;
          data["startedAt"] *= 1000;
          data["createdAt"] *= 1000;
          data["updatedAt"] *= 1000;
          return {
            data: data
          };
        }
      };
    case QueryTypes.GET_LIST:
      return {
        query: gql`
          query {
            org {
              id
              displayName
              projectList {
                pageInfo {
                  endCursor
                }
                projects {
                  id
                  title
                  description
                  status
                  type
                  startedAt
                  createdAt
                  updatedAt
                  createdBy
                  updatedBy
                  dataExportEnabled
                  totalProjectPatientCount
                  organizationId
                }
              }
            }
          }
        `,
        // TODO Implement pagination.
        variables: {},
        parseResponse: (response) => {
          const data = response.data.org.projectList.projects.map(
            (project: Project) => {
              return {
                ...project,
                startedAt: project.startedAt * 1000,
                createdAt: project.createdAt * 1000,
                updatedAt: project.updatedAt * 1000
              };
            }
          );
          return {
            data: clientSideSorting(data, params),
            total: data.length
          };
        }
      };
    case QueryTypes.GET_ONE:
      if (params.meta.patientListV2) {
        return {
          query: gql`
            query ($projectId: ID!, $metricCategories: [MetricCategoryEnum]) {
              org {
                displayName
              }
              project(id: $projectId) {
                id
                title
                description
                status
                type
                startedAt
                createdAt
                updatedAt
                dataExportEnabled
                config
                organizationId
                referralCode {
                  code
                  appMode {
                    id
                    displayName
                  }
                }
                cohortList {
                  cohorts {
                    id
                    title
                    cohortPatientList {
                      aggregatedMetrics {
                        totalPatientCount
                        patientsWithoutSymptomDataCount
                        patientsWithoutHeartRateDataCount
                        patientsWithoutHeartRateAndSymptomDataCount
                      }
                    }
                  }
                }
                projectPatientList {
                  projectPatients {
                    patient {
                      id
                      striveUserId
                    }
                    codeName
                    metricCategoryTimeRanges(
                      metricCategories: $metricCategories
                    ) {
                      metricCategory
                      startTime
                      endTime
                    }
                  }
                }
              }
            }
          `,
          variables: {
            projectId: params.id,
            metricCategories: params.meta.metricCategories
          },
          parseResponse: (response) => {
            const dataAvailabilityThresholdDaysInSeconds =
              DATA_AVAILABILITY_THRESHOLD_DAYS * SEC_PER_DAY;

            const data = { ...response.data.project };
            data["org.displayName"] = response.data.org.displayName;
            data["startedAt"] *= 1000;
            data["createdAt"] *= 1000;
            data["updatedAt"] *= 1000;

            const projectPatients =
              response.data.project.projectPatientList.projectPatients;
            const projectPatientsData = projectPatients.map(
              (projectPatient: PatientMetricData) => {
                const res: PatientMetricData = {
                  ...projectPatient,
                  id: projectPatient.patient.id
                };

                for (const metricCategoryTimeRange of projectPatient.metricCategoryTimeRanges) {
                  res[metricCategoryTimeRange.metricCategory] =
                    metricCategoryTimeRange.endTime;
                }

                return res;
              }
            );

            const totalPatients = projectPatientsData.length;
            const categoryCounts: { [key: string]: number } = {};
            const noDataForTenDaysPatients = new Set();

            for (const metricCategory of params.meta.metricCategories) {
              categoryCounts[metricCategory] = 0;
            }

            projectPatientsData.forEach((patient: PatientMetricData) => {
              let noDataForAnyCategory = false;

              params.meta.metricCategories.forEach((metricCategory: string) => {
                const endTime = patient[metricCategory];
                if (
                  isDataOutdated(
                    endTime,
                    dataAvailabilityThresholdDaysInSeconds
                  )
                ) {
                  categoryCounts[metricCategory]++;
                  noDataForTenDaysPatients.add(patient.id);
                  noDataForAnyCategory = true;
                }
              });

              if (noDataForAnyCategory) {
                noDataForTenDaysPatients.add(patient.id);
              }
            });

            data["dataCategoryCounts"] = {
              totalPatientCount: totalPatients,
              categoryCounts: categoryCounts,
              noDataForAnyCategoryCount: noDataForTenDaysPatients.size
            };
            return {
              data: data
            };
          }
        };
      }
      return {
        query: gql`
          query ($projectId: ID!) {
            org {
              displayName
            }
            project(id: $projectId) {
              id
              title
              description
              status
              type
              startedAt
              createdAt
              updatedAt
              dataExportEnabled
              config
              organizationId
              referralCode {
                code
                appMode {
                  id
                  displayName
                }
              }
              cohortList {
                cohorts {
                  id
                  title
                  cohortPatientList {
                    aggregatedMetrics {
                      totalPatientCount
                      patientsWithoutSymptomDataCount
                      patientsWithoutHeartRateDataCount
                      patientsWithoutHeartRateAndSymptomDataCount
                    }
                  }
                }
              }
              projectPatientList {
                aggregatedMetrics {
                  totalPatientCount
                  patientsWithoutSymptomDataCount
                  patientsWithoutHeartRateDataCount
                  patientsWithoutHeartRateAndSymptomDataCount
                }
                projectPatients {
                  patient {
                    id
                    striveUserId
                  }
                  codeName
                }
              }
            }
          }
        `,
        variables: { projectId: params.id },
        parseResponse: (response) => {
          const data = { ...response.data.project };
          data["org.displayName"] = response.data.org.displayName;
          data["startedAt"] *= 1000;
          data["createdAt"] *= 1000;
          data["updatedAt"] *= 1000;
          return {
            data: data
          };
        }
      };
    case QueryTypes.UPDATE:
      return {
        query: gql`
          mutation ($input: UpdateProjectInput) {
            updateProject(input: $input) {
              project {
                id
                title
                description
                status
                type
                config
                startedAt
                createdAt
                updatedAt
                createdBy
                updatedBy
              }
            }
          }
        `,
        variables: {
          input: {
            id: params.data.id,
            title: params.data.title,
            description: params.data.description ? params.data.description : "",
            type: params.data.type,
            startedAt: new Date(params.data.startedAt).getTime() / 1000,
            config:
              typeof params.data.config === "string"
                ? params.data.config
                : JSON.stringify({ ui_config: params.data.config ?? {} })
          }
        },
        parseResponse: (response) => {
          const data = { ...response.data.updateProject.project };
          data["startedAt"] *= 1000;
          data["createdAt"] *= 1000;
          data["updatedAt"] *= 1000;
          return {
            data: data
          };
        }
      };
    case QueryTypes.DELETE:
      return {
        query: gql`
          mutation ($id: ID!) {
            removeProject(id: $id) {
              status
            }
          }
        `,
        variables: {
          id: params.id
        },
        parseResponse: (response) => {
          return {
            data: response.data.removeProject.status,
            total: response.data.removeProject.status.length
          };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryProject;
