import { Typography } from "@mui/material";
import { Datagrid, ReferenceManyField, TextField } from "react-admin";
import { NoResourceMessage } from "src/components/common/NoResourceMessage";
import { StudyDatagridHeader } from "src/components/common/StudyDataGrid/StudyDatagridHeader";
import { RuneDateField } from "src/components/fields/RuneDateField";

const NoEventMessage = () => (
  <NoResourceMessage>No events reported.</NoResourceMessage>
);

const dateFmtOptions = { year: "numeric", month: "short", day: "numeric" };

const groupedLabels = [
  { subLabels: [{ name: "Date", sortable: false }] },
  { subLabels: [{ name: "Type", sortable: false }] },
  { subLabels: [{ name: "Note", sortable: false }] }
];

const PatientEventList = () => {
  return (
    <ReferenceManyField label="Logs" reference="PatientEvent" target="id">
      <Datagrid
        empty={<NoEventMessage />}
        header={<StudyDatagridHeader groupedLabels={groupedLabels} />}
        bulkActionButtons={false}
      >
        <RuneDateField
          source="date"
          label="Date"
          showTime={true}
          dateFmtOptions={dateFmtOptions}
        />
        <TextField source="classification.category" label="Type" />
        <TextField source="displayNote" label="Note" />
      </Datagrid>
      <Typography variant="body2" sx={{ fontStyle: "italic", m: 1 }}>
        Reported events for the last 30 days
      </Typography>
    </ReferenceManyField>
  );
};

export default PatientEventList;
