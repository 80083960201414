import * as React from "react";
import {
  Edit,
  EditProps,
  SelectInput,
  required,
  SimpleForm,
  BooleanInput,
  Toolbar,
  TabbedShowLayout,
  Tab,
  TextField,
  Labeled,
  useUpdate,
  useRedirect,
  useNotify
} from "react-admin";
import mixpanel from "mixpanel-browser";

import { runeTheme } from "../../../common/RuneTheme";
import { RuneDateField } from "../../../fields/RuneDateField";

import {
  DetailPageGridContainer,
  DetailPageTopRow,
  DetailPageBottomRow,
  DetailPageCol
} from "../../../common/DetailPageGrid";
import {
  roleDisplayNameChoices,
  roleDisplayNameToMutationInputs
} from "../MemberInviteForm";
import MemberHeader from "./MemberHeader";
import RuneCancelButton from "../../../common/buttons/RuneCancelButton";
import RuneSaveButton from "../../../common/buttons/RuneSaveButton";
import iconCheckMark from "../../../../shared/icon/checkmark.svg";
import iconCancel from "../../../../shared/icon/cancel.svg";

const MemberEdit = (props: EditProps) => {
  const dateOptions = { year: "numeric", month: "short", day: "numeric" };

  const [update] = useUpdate();
  const redirect = useRedirect();
  const notify = useNotify();

  // https://runelabs.atlassian.net/browse/SW-2470
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const MemberEditSave = (data: any) => {
    mixpanel.track("MemberEditSave", { data: data });

    const { role } = data;
    let { disabled } = data;
    const { admin, canSeePHI } = roleDisplayNameToMutationInputs(
      role.displayName
    );

    const membershipId = data.id;
    const orgId = data.org.id;
    const memberOverviewPath = `/Member/${membershipId}/show`;
    const orgMemberPath = `/Org/${orgId}/show/1`;

    // If `disabled` was never set on the initial record (i.e., if the membership was created and
    // never disabled), it will appear here as an empty string, so it needs to be converted to a boolean.
    disabled = Boolean(disabled);

    update(
      "Member",
      { data: { membershipId, disabled, admin, canSeePHI } },
      {
        onSuccess() {
          if (!disabled) redirect(memberOverviewPath);
          else {
            notify("The member has been removed from the organization");
            redirect(orgMemberPath);
          }
        },
        onError(error) {
          if (error instanceof Error) {
            notify(error.message, {
              type: "error"
            });
          } else {
            notify("Unknown error occurred. Unable to update member.", {
              type: "error"
            });
          }
        }
      }
    );
  };

  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      redirect="show"
      actions={false}
      title="Edit Membership"
      component="div"
      sx={{
        width: "100%",
        padding: "0 30px"
      }}
    >
      <>
        <MemberHeader />
        <TabbedShowLayout>
          <Tab label="Overview" sx={{ textTransform: "capitalize" }}>
            <DetailPageGridContainer>
              <DetailPageTopRow>
                <DetailPageCol sx={{ width: "450px" }}>
                  <Labeled label="DISPLAY NAME">
                    <TextField
                      source="user.displayName"
                      sx={{
                        fontSize: "1.4em",
                        color: runeTheme.palette.primary.main
                      }}
                    />
                  </Labeled>
                </DetailPageCol>

                <DetailPageCol>
                  <Labeled label="CREATED ON">
                    <RuneDateField
                      source="created"
                      showTime={true}
                      dateFmtOptions={dateOptions}
                    />
                  </Labeled>
                </DetailPageCol>
              </DetailPageTopRow>

              <SimpleForm
                toolbar={<MembershipEditToolbar />}
                onSubmit={MemberEditSave}
                sx={{ padding: "0" }}
              >
                <DetailPageBottomRow>
                  <DetailPageCol sx={{ width: "450px" }}>
                    <Labeled label="USER EMAIL">
                      <TextField source="user.email" />
                    </Labeled>

                    <Labeled label="ORGANIZATION">
                      <TextField source="org.displayName" />
                    </Labeled>

                    <Labeled label="USER ID">
                      <TextField source="user.id" />
                    </Labeled>

                    <Labeled label="ENABLED">
                      <BooleanInput
                        source="disabled"
                        label="Yes"
                        format={(v) => !v}
                        parse={(v) => !v}
                      />
                    </Labeled>
                  </DetailPageCol>

                  <DetailPageCol>
                    <SelectInput
                      variant="outlined"
                      source="role.displayName"
                      label="ROLE"
                      choices={roleDisplayNameChoices}
                      isRequired
                      validate={required()}
                      sx={{ width: "100px" }}
                    />

                    <Labeled label="STATUS">
                      <TextField source="status.displayName" />
                    </Labeled>

                    <Labeled label="MEMBERSHIP ID">
                      <TextField source="id" />
                    </Labeled>
                  </DetailPageCol>
                </DetailPageBottomRow>
              </SimpleForm>
            </DetailPageGridContainer>
          </Tab>
        </TabbedShowLayout>
      </>
    </Edit>
  );
};

const MembershipEditToolbar = () => {
  return (
    <Toolbar
      sx={{
        background: runeTheme.palette.background.light,
        display: "flex",
        justifyContent: "space-between",
        width: "240px"
      }}
      disableGutters={true}
    >
      <RuneCancelButton buttonType="SHOW" icon={iconCancel} label={"CANCEL"} />
      <RuneSaveButton icon={iconCheckMark} label={"SAVE"} />
    </Toolbar>
  );
};

export default MemberEdit;
