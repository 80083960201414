import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import { QueryTypes } from "../../constants";

interface Member {
  id: string;
  disabled: boolean;
  user: {
    id: string;
    displayName: string;
    email: string;
  };
  status: {
    displayName: string;
    invitedAt: number;
  };
  role: {
    displayName: string;
  };
}

interface BuildQueryMemberParams {
  id: string;
  meta: {
    nextPageCursor: string;
  };
  data: {
    orgId: string;
    email: string;
    displayName: string;
    admin: boolean;
    canSeePHI: boolean;
    defaultLoginPortal: string;

    membershipId: string;
    disabled: boolean;
  };
}

const buildQueryMember = (
  fetchType: string,
  params: BuildQueryMemberParams
): BuildQueryResult => {
  switch (fetchType) {
    case QueryTypes.GET_ONE:
      return {
        query: gql`
          query ($membershipId: ID!) {
            membership(membershipId: $membershipId) {
              id
              disabled
              created
              user {
                id
                displayName
                email
              }
              status {
                displayName
                invitedAt
              }
              role {
                displayName
              }
              org {
                id
                displayName
              }
            }
          }
        `,
        variables: { membershipId: params.id },
        parseResponse: (response) => {
          return {
            data: response.data.membership
          };
        }
      };
    case QueryTypes.GET_MANY_REFERENCE:
      return {
        query: gql`
          query ($orgId: ID!, $cursor: Cursor) {
            org(orgId: $orgId) {
              membershipList(withDisabled: true, cursor: $cursor) {
                memberships {
                  id
                  disabled
                  user {
                    id
                    displayName
                    email
                  }
                  status {
                    displayName
                    invitedAt
                  }
                  role {
                    displayName
                  }
                }
                pageInfo {
                  endCursor
                }
              }
            }
          }
        `,
        // TODO Implement pagination.
        variables: { orgId: params.id, cursor: params.meta.nextPageCursor },
        parseResponse: (response) => {
          return {
            data: response.data.org.membershipList.memberships.map(
              (member: Member) => ({
                ...member
              }),
              params
            ),
            total: response.data.org.membershipList.memberships.length,
            endCursor: response.data.org.membershipList.pageInfo.endCursor
          };
        }
      };
    case QueryTypes.CREATE:
      return {
        query: gql`
          mutation ($input: CreateMembershipInput!) {
            createMembership(input: $input) {
              id
              disabled
              user {
                id
                displayName
                email
              }
              status {
                displayName
                invitedAt
              }
              role {
                displayName
              }
            }
          }
        `,
        variables: {
          input: {
            orgId: params.data.orgId,
            email: params.data.email,
            displayName: params.data.displayName,
            admin: params.data.admin,
            canSeePHI: params.data.canSeePHI,
            defaultLoginPortal: params.data.defaultLoginPortal
          }
        },
        parseResponse: (response) => {
          return { data: response.data.createMembership };
        }
      };
    case QueryTypes.UPDATE:
      if (params.data.disabled) {
        return {
          query: gql`
            mutation ($input: UpdateMembershipInput!) {
              updateMembership(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: {
              membershipId: params.data.membershipId,
              disabled: params.data.disabled
            }
          },
          parseResponse: (response) => {
            return { data: response.data.updateMembership };
          }
        };
      } else {
        return {
          query: gql`
            mutation ($input: UpdateMembershipInput!) {
              updateMembership(input: $input) {
                id
                disabled
                user {
                  id
                  displayName
                  email
                }
                status {
                  displayName
                  invitedAt
                }
                role {
                  displayName
                }
              }
            }
          `,
          variables: {
            input: {
              membershipId: params.data.membershipId,
              disabled: params.data.disabled,
              admin: params.data.admin,
              canSeePHI: params.data.canSeePHI
            }
          },
          parseResponse: (response) => {
            return { data: response.data.updateMembership };
          }
        };
      }
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryMember;
