import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import { PercentageBarChart } from "src/components/common/PercentageBarChart/PercentageBarChart";
import { getAllDyskinesiaStreamsParams } from "src/providers/streams/runeStreamsUtils";

interface PatientDailyDyskinesiaBarChartProps {
  date: Date | undefined;
  patientId: string;
}

export const PatientDailyDyskinesiaBarChart = (
  props: PatientDailyDyskinesiaBarChartProps
) => {
  const dataProvider = useDataProvider();
  const [rechartDyskinesiaData, setRechartDyskinesiaData] = useState<
    {
      timestamp: number;
      [key: number]: number;
    }[]
  >([]);

  const [visibleSeries, setVisibleSeries] = useState<{
    [key: string]: boolean;
  }>({
    present: true,
    none: true
  });

  const handleLegendClick = (e: { dataKey: string }) => {
    const { dataKey } = e;

    setVisibleSeries((prevVisibleSeries) => ({
      ...prevVisibleSeries,
      [dataKey]: !prevVisibleSeries[dataKey]
    }));
  };

  function seriesColor(dataKey: string): string | undefined {
    switch (dataKey) {
      case "present":
        return "#33A02C";
      case "none":
        return "#004271";
      default:
        return "#A2A8AD";
    }
  }

  useEffect(() => {
    // TODO: Address timezone management as part of SW-3563
    // get browser time zone for now
    const timezone = moment.tz.guess();

    // Create a Moment object with browser time zone
    const localMoment = moment.tz(props.date, timezone);

    // Calculate start time (midnight) and end time (one second before midnight) in UTC
    const startDateMoment = localMoment.clone().startOf("day");
    const endDateMoment = localMoment.clone().endOf("day");

    // First, we get ALL Dyskinesia streams for the patient (across all devices)
    Promise.all([
      dataProvider.getStreamIds(
        getAllDyskinesiaStreamsParams(props.patientId.toString())
      )
    ])
      // Then, we get the data for each stream
      .then(([dyskinesiaStreamsMetadata]) => {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        const streamsDataPromises: Promise<any>[] = [];

        dyskinesiaStreamsMetadata.data.forEach(
          (streamMetadata: { id: string }) => {
            streamsDataPromises.push(
              dataProvider
                .getStreamAggregateWindow(streamMetadata.id, {
                  start_time: startDateMoment.unix(),
                  end_time: endDateMoment.unix(),
                  resolution: JSON.stringify(15 * 60),
                  aggregate_function: "mean",
                  // TODO: Address timezone management as part of SW-3563
                  timezone_name: timezone
                })
                .then((streamData: any) => {
                  return {
                    streamData: streamData,
                    streamMetadata: streamMetadata
                  };
                })
            );
          }
        );
        return Promise.all(streamsDataPromises);
      })
      .then((streamsData) => {
        console.log("dyskinesia - streamsData", streamsData);
        const streamsDataIndexedByTimestamp: any = {};

        //We want to initialize streamsDataIndexedByTimestamp with one timestamp every 15 minutes between the start and end date
        const currentMoment = startDateMoment.clone();
        while (currentMoment.isSameOrBefore(endDateMoment)) {
          streamsDataIndexedByTimestamp[currentMoment.unix()] = {};
          currentMoment.add(15, "minutes");
        }

        streamsData.forEach((streamDataResult: any) => {
          for (
            let i = 0;
            i < streamDataResult.streamData.json.cardinality;
            i++
          ) {
            const timestamp = streamDataResult.streamData.json.data.time[i];
            const duration_sum =
              streamDataResult.streamData.json.data.duration_sum[i];
            const percentage =
              streamDataResult.streamData.json.data.aggregate_values[i];

            // This should never happen, as we initialized streamsDataIndexedByTimestamp with timestamps
            // every minute between start and end date
            if (!(timestamp in streamsDataIndexedByTimestamp))
              streamsDataIndexedByTimestamp[timestamp] = {};

            if (duration_sum) {
              streamsDataIndexedByTimestamp[timestamp]["present"] = percentage;
              streamsDataIndexedByTimestamp[timestamp]["none"] = 1 - percentage;
            }
          }
        });

        // We got all the data, now we need to format it for Recharts
        const timestamps = Object.keys(streamsDataIndexedByTimestamp);
        timestamps.sort();
        const streamsDataSorted = timestamps.map((timestamp) => ({
          timestamp: new Date(Number(timestamp) * 1000),
          ...streamsDataIndexedByTimestamp[timestamp]
        }));
        setRechartDyskinesiaData(streamsDataSorted);
      });
  }, [props.date, props.patientId]);

  return (
    <PercentageBarChart
      seriesColor={seriesColor}
      visibleSeries={visibleSeries}
      handleLegendClick={handleLegendClick}
      data={rechartDyskinesiaData}
      title="Dyskinesia"
      subTitle="Percentage of time with dyskinesia, aggregated in 15-min intervals"
    />
  );
};
