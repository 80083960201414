import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import {
  AggregatedMetric,
  DATA_AVAILABILITY_THRESHOLD_DAYS,
  QueryTypes,
  SEC_PER_DAY
} from "../../constants";
import { calculateAverageDataPerDay, isDataOutdated } from "./utilities";
import { PatientMetricData } from "./buildQueryProjectPatient";

// https://runelabs.atlassian.net/browse/SW-2470
/* eslint-disable @typescript-eslint/no-explicit-any */

type Patient = {
  id: string;
  createdAt: string;
  realName: string;
  email: string;
  [key: string]: any;
};

const buildQueryCohortPatient = (
  fetchType: string,
  params: any
): BuildQueryResult => {
  const sortInput: any = {};
  const filterInput: any = {};
  let gqlQuery = `mutation($cohortIds: [ID]!) { \n`;

  switch (fetchType) {
    case QueryTypes.CREATE:
      return {
        query: gql`
          mutation ($input: CreateCohortPatientInput!) {
            addPatientToCohort(input: $input) {
              cohortPatient {
                patient {
                  id
                }
                codeName
                createdAt
                updatedAt
                createdBy
                updatedBy
              }
            }
          }
        `,
        variables: {
          input: {
            cohortId: params.data.cohortId,
            patientId: params.data.patientId
          }
        },
        parseResponse: (response) => {
          return {
            data: response.data,
            total: response.data.length
          };
        }
      };
    case QueryTypes.GET_MANY_REFERENCE:
      if (params.filter.patientListV2) {
        console.log("params.filter", params.filter);
        return {
          query: gql`
            query getCohort(
              $cohortId: ID,
              $metricCategories: [MetricCategoryEnum]
            ) {
              cohort(id: $cohortId) {
                id
                cohortPatientList {
                  cohortPatients {
                    patient {
                      id
                      striveUserId
                      createdAt
                      ${
                        params.filter.enablePiiToggle
                          ? "identifiableInfo { realName email }"
                          : ""
                      }
                      appMode {
                        id
                        displayName
                      }
                    }
                    codeName
                    metricCategoryTimeRanges(metricCategories: $metricCategories) {
                      metricCategory
                      startTime
                      endTime
                    }
                  }
                  pageInfo {
                    codeNameEndCursor
                    metricsEndCursor
                  }
                }
              }
            }
          `,
          variables: {
            cohortId: params.filter.cohortId,
            timeInterval: params.filter.dataAvailability,
            metricCategories: params.filter.metricCategories
          },
          parseResponse: (response) => {
            const dataAvailabilityThresholdDaysInSeconds =
              DATA_AVAILABILITY_THRESHOLD_DAYS * SEC_PER_DAY;
            const cohortPatients =
              response.data.cohort.cohortPatientList.cohortPatients;

            const metricsData = cohortPatients.map(
              (cohortPatient: PatientMetricData) => {
                const res: any = {
                  ...cohortPatient,
                  id: cohortPatient.patient.id,
                  createdAt: cohortPatient.patient.createdAt,
                  realName: cohortPatient.patient.identifiableInfo?.realName,
                  email: cohortPatient.patient.identifiableInfo?.email,
                  appMode: cohortPatient.patient.appMode?.displayName
                };

                for (const metricCategoryTimeRange of cohortPatient.metricCategoryTimeRanges) {
                  res[metricCategoryTimeRange.metricCategory] =
                    metricCategoryTimeRange.endTime;
                }

                return res;
              }
            );

            const totalPatients = metricsData.length;
            const categoryCounts: { [key: string]: number } = {};
            const noDataForTenDaysPatients = new Set();

            for (const metricCategory of params.filter.metricCategories) {
              categoryCounts[metricCategory] = 0;
            }

            metricsData.forEach((patient: PatientMetricData) => {
              let noDataForAnyCategory = false;

              params.filter.metricCategories.forEach(
                (metricCategory: string) => {
                  const endTime = patient[metricCategory];
                  if (
                    isDataOutdated(
                      endTime,
                      dataAvailabilityThresholdDaysInSeconds
                    )
                  ) {
                    categoryCounts[metricCategory]++;
                    noDataForTenDaysPatients.add(patient.id);
                    noDataForAnyCategory = true;
                  }
                }
              );

              if (noDataForAnyCategory) {
                noDataForTenDaysPatients.add(patient.id);
              }
            });

            const filteredPatients = metricsData.filter((patient: Patient) => {
              if (params.filter.qcMetricCategory === "ANY") {
                return noDataForTenDaysPatients.has(patient.id);
              } else {
                const endTime =
                  patient[params.filter.qcMetricCategory as string];
                return isDataOutdated(
                  endTime,
                  dataAvailabilityThresholdDaysInSeconds
                );
              }
            });

            return {
              data: filteredPatients,
              total: totalPatients,
              categoryCounts: categoryCounts,
              noDataForAnyCategoryCount: noDataForTenDaysPatients.size
            };
          }
        };
      } else {
        if (params.sort.field !== "id" && params.sort.field !== "codeName") {
          const [sortDataType, ...rest] = params.sort.field.split("@");
          let sortType = rest[0];
          if (sortType === "AVG_PER_DAY")
            // "AVG_PER_DAY" is computed on the frontend based on "TOTAL_HOURS"
            sortType = "TOTAL_HOURS";
          sortInput["metricDataType"] = sortDataType;
          sortInput["metricType"] = sortType;
        }
        sortInput["direction"] = params.sort.order;

        filterInput["timeInterval"] = params.filter.dataAvailability;

        if (params.filter.qcMetric !== 0) {
          switch (params.filter.qcMetric) {
            case 1:
              filterInput["aggregatedMetric"] =
                AggregatedMetric.NO_RECENT_SYMPTOM_DATA;
              break;
            case 2:
              filterInput["aggregatedMetric"] =
                AggregatedMetric.NO_RECENT_HR_DATA;
              break;
            case 3:
              filterInput["aggregatedMetric"] =
                AggregatedMetric.NO_RECENT_HR_AND_SYMPTOM_DATA;
              break;
          }
        }

        return {
          query: gql`
            query getCohort(
              $cohortId: ID
              $sortInput: SortInput
              $filterInput: FilterInput
              $timeInterval: MetricTimeInterval
            ) {
              cohort(id: $cohortId) {
                id
                cohortPatientList(
                  sortInput: $sortInput
                  filterInput: $filterInput
                ) {
                  cohortPatients {
                    patient {
                      id
                      striveUserId
                      createdAt
                    }
                    codeName
                    metricList(timeInterval: $timeInterval) {
                      metrics {
                        id
                        updatedAt
                        createdAt
                        type
                        dataType
                        value
                        timeInterval
                      }
                    }
                  }
                  pageInfo {
                    codeNameEndCursor
                    metricsEndCursor
                  }
                }
              }
            }
          `,
          variables: {
            cohortId: params.filter.cohortId,
            timeInterval: params.filter.dataAvailability,
            filterInput: filterInput,
            sortInput: sortInput
          },
          parseResponse: (response) => {
            const cohortPatients =
              response.data.cohort.cohortPatientList.cohortPatients;
            const data = cohortPatients.map(
              (cohortPatient: { metricList: any; patient: { id: any } }) => {
                const res: any = {
                  ...cohortPatient,
                  id: cohortPatient.patient.id
                };

                cohortPatient.metricList.metrics.forEach(
                  (metric: { dataType: string; type: string; value: any }) => {
                    res[metric.dataType + "@" + metric.type] = metric.value;
                  }
                );

                // Avg/Day computation for Tremor/Dyskinesia
                if ("APPLEWATCH_SYMPTOM@TOTAL_HOURS" in res) {
                  res["APPLEWATCH_SYMPTOM@AVG_PER_DAY"] =
                    calculateAverageDataPerDay(
                      params.filter.dataAvailability,
                      res["APPLEWATCH_SYMPTOM@TOTAL_HOURS"],
                      params.filter.projectStartDate,
                      res.createdAt
                    );
                }
                return res;
              }
            );

            return {
              data: data,
              total:
                response.data.cohort.cohortPatientList.cohortPatients.length
            };
          }
        };
      }
    case QueryTypes.DELETE_MANY:
      params.ids.forEach((id: string, index: number) => {
        gqlQuery +=
          "aliasDelete" +
          index +
          ': removePatientFromCohorts(cohortIds: $cohortIds, patientId: "' +
          id +
          '") { status } \n';
      });
      gqlQuery += "}\n";

      return {
        query: gql(gqlQuery),
        variables: {
          cohortIds: [params.meta.cohortId]
        },
        parseResponse: () => {
          return { data: [] };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryCohortPatient;
