import { useDataProvider, useRecordContext } from "react-admin";
import get from "lodash/get";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Tooltip, AreaChart, Area } from "recharts";
import {
  bucketAvaibilityStreamsForExistingTimestamps,
  getAggregateWindowPromises,
  getDataAvailabilityConfiguration,
  getHkHeartrateStreamsParams,
  getStreamAvailabilityPromises,
  getTremorSeverityAllStreamsParams,
  initializeDataAvailabilityData,
  labelPromisesResults,
  populateDataAvailabilityFromAggregateWindowStreams
} from "src/providers/streams/runeStreamsUtils";
import moment from "moment-timezone";

interface DataAvailabilitySummaryFieldProps {
  source: string;
  dataAvailability: string;
  projectStart: Date;
  label: string;
}

export const DataAvailabilitySummaryField = (
  props: DataAvailabilitySummaryFieldProps
) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const patientId = get(record, "id");

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const [rechartDataAvailability, setRechartDataAvailability] = useState<any[]>(
    []
  );

  useEffect(() => {
    setRechartDataAvailability([]);

    Promise.all([
      dataProvider.getStreamIds(
        getTremorSeverityAllStreamsParams(patientId.toString())
      ),
      dataProvider.getStreamIds(
        getHkHeartrateStreamsParams(patientId.toString())
      )
    ])
      /* eslint-disable @typescript-eslint/no-explicit-any */
      .then(([tremorStreamsMetadata, hkHeartrateStreamsMetadata]) => {
        const { startDate, aggregateWindowResolution, availabilityResolution } =
          getDataAvailabilityConfiguration(
            props.dataAvailability,
            props.projectStart
          );

        // Trying to fix some timezone things (not fully functional yet)
        // get browser time zone for now
        const timezone = moment.tz.guess();
        const dateString = startDate.toLocaleDateString("en-US", {
          timeZone: timezone
        });
        const midnightUTCDate = new Date(dateString);
        midnightUTCDate.setHours(17, 0, 0, 0);

        const startDateTimestamp = Math.floor(midnightUTCDate.getTime() / 1000);
        const endDateTimestamp = Date.now() / 1000;

        const tremorStreamsMetadataAggregateWindowPromises: Promise<any>[] =
          getAggregateWindowPromises(
            tremorStreamsMetadata,
            dataProvider,
            aggregateWindowResolution,
            startDateTimestamp,
            endDateTimestamp
          );

        const hkHeartrateStreamsMetadataAvailabilityPromises: Promise<any>[] =
          getStreamAvailabilityPromises(
            hkHeartrateStreamsMetadata,
            dataProvider,
            availabilityResolution,
            startDateTimestamp,
            endDateTimestamp
          );

        return Promise.all([
          labelPromisesResults(
            tremorStreamsMetadataAggregateWindowPromises,
            "tremorStreams"
          ),
          labelPromisesResults(
            hkHeartrateStreamsMetadataAvailabilityPromises,
            "hkHeartrateStreams"
          )
        ]).then(
          ([
            tremorStreamsAggregateWindow,
            hkHeartrateStreamsAvailabilityPromises
          ]) => {
            const dataAvailabilityData = initializeDataAvailabilityData(
              tremorStreamsAggregateWindow,
              startDateTimestamp,
              endDateTimestamp,
              aggregateWindowResolution
            );

            populateDataAvailabilityFromAggregateWindowStreams(
              dataAvailabilityData,
              tremorStreamsAggregateWindow,
              "tremorDuration"
            );
            bucketAvaibilityStreamsForExistingTimestamps(
              hkHeartrateStreamsAvailabilityPromises,
              dataAvailabilityData,
              availabilityResolution,
              "hkHeartrateDuration"
            );

            const timestamps = Object.keys(dataAvailabilityData);
            timestamps.sort();
            const dataAvailabilitySorted = timestamps.map((timestamp) => ({
              timestamp: new Date(Number(timestamp) * 1000),
              ...dataAvailabilityData[Number(timestamp)]
            }));

            setRechartDataAvailability(dataAvailabilitySorted);
          }
        );
      });
  }, [props.dataAvailability]);

  interface CustomTooltipProps {
    active?: boolean;
    payload?: payloadType[];
    label?: number;
  }
  type payloadType = {
    value: string | number;
    name: string;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    payload: any;
  };

  // TODO: Add Weekly/Daily
  const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">
            <b>{`${payload[0].payload.timestamp.toLocaleDateString()} `}</b>
            <br />
            T/D:{" "}
            {`${parseFloat(payload[0].payload.tremorDuration + "").toFixed(2)}`}
            <br />
            HR:{" "}
            {`${parseFloat(payload[0].payload.hkHeartrateDuration + "").toFixed(
              2
            )}`}
            <br />
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <Box sx={{ height: 50, width: 250 }}>
      <AreaChart
        width={250}
        height={50}
        data={rechartDataAvailability}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0
        }}
      >
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ fill: "transparent" }}
          position={{ y: -50 }}
        />
        <Area
          type="step"
          dataKey="tremorDuration"
          stroke="#2E32A0"
          fill="#2E32A0"
          fillOpacity={0.35}
          dot={false}
          strokeWidth={1}
        />
        <Area
          type="step"
          dataKey="hkHeartrateDuration"
          stroke="#C45AF6"
          fill="#C45AF6"
          fillOpacity={0.35}
          dot={false}
          strokeWidth={1}
        />
      </AreaChart>
    </Box>
  );
};
