import { styled } from "@mui/material";

export interface BadgeProps {
  bgColor?: string;
  display?: string;
}

export const Badge = styled("div")<BadgeProps>(({ bgColor, display }) => ({
  display: display,
  alignItems: "center",
  padding: "3px 7px",
  height: "18px",
  borderRadius: "4px",
  background: bgColor,
  color: "#FFFFFF",
  fontSize: "13px",
  fontWeight: "400",
  lineHeight: "14px",
  letterSpacing: "-0.03em"
}));
