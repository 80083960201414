import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import { QueryTypes } from "../../constants";

interface BuildQueryPatientParams {
  id: string;
  ids: string[];
  meta: {
    orgId: string;
    nextPageCursor: string;
  };
  data: {
    codeName: string;
    appModeId: string;
    patientId: string;
  };
}

interface PatientAccess {
  id: number;
  createdAt: string;
  patient: {
    id: number;
    codeName: string;
    createdAt: string;
    identifiableInfo: {
      realName: string;
    };
    deviceList: {
      devices: {
        id: number;
        deviceShortId: string;
        alias: string;
        disabled: boolean;
        deviceType: {
          displayName: string;
        };
      }[];
    };
  };
}

const buildQueryPatient = (
  fetchType: string,
  params: BuildQueryPatientParams
): BuildQueryResult => {
  switch (fetchType) {
    case QueryTypes.GET_ONE:
      return {
        query: gql`
          query ($id: ID!) {
            org {
              id
              displayName
            }
            patient(id: $id) {
              id
              codeName
              createdAt
              identifiableInfo {
                realName
                email
              }
              striveUserId
              appMode {
                id
                displayName
              }
            }
          }
        `,
        variables: { id: params.id },
        parseResponse: (response) => {
          const data = { ...response.data.patient };
          data["org"] = response.data.org;
          data["appModeDisplayName"] =
            response.data.patient.appMode?.displayName;
          return {
            data: data
          };
        }
      };
    // Get all Patients in a single Org.
    case QueryTypes.GET_MANY_REFERENCE:
      return {
        query: gql`
          query ($orgId: ID!, $cursor: Cursor) {
            org(orgId: $orgId) {
              id
              patientAccessList(cursor: $cursor) {
                patientAccess {
                  id
                  createdAt
                  patient {
                    id
                    codeName
                    createdAt
                    identifiableInfo {
                      realName
                      email
                    }
                    deviceList {
                      devices {
                        id
                        deviceShortId
                        alias
                        disabled
                        deviceType {
                          displayName
                        }
                      }
                    }
                  }
                }
                pageInfo {
                  endCursor
                }
              }
            }
          }
        `,
        // TODO Implement pagination.
        variables: { orgId: params.id, cursor: params.meta.nextPageCursor },
        parseResponse: (response) => {
          return {
            data: response.data.org.patientAccessList.patientAccess.map(
              (pa: PatientAccess) => ({
                ...pa.patient,
                patientAccessCreatedAt: pa.createdAt
              })
            ),
            total: response.data.org.patientAccessList.patientAccess.length,
            endCursor: response.data.org.patientAccessList.pageInfo.endCursor
          };
        }
      };
    case QueryTypes.UPDATE:
      return {
        query: gql`
          mutation ($input: UpdatePatientInput!) {
            updatePatient(input: $input) {
              patient {
                id
                codeName
                appMode {
                  id
                  displayName
                }
              }
            }
          }
        `,
        variables: {
          input: {
            codeName: params.data.codeName,
            appModeId: params.data.appModeId,
            patientId: params.data.patientId
          }
        },
        parseResponse: (response) => {
          const patient = response.data?.updatePatient?.patient;
          response.data.id = patient.id;
          return {
            data: response.data,
            total: response.data.length
          };
        }
      };

    case QueryTypes.DELETE_MANY:
      // This is actually disabling PatientAccess records within an Org, but since the Patient list in an Org
      // is implemented by fetching a Patient GET_MANY_REFERENCE, it's easiest to model this as deleting Patients,
      // especially since the mutation takes Patient IDs rather than PatientAccess IDs.
      return {
        query: gql`
          mutation RemovePatientsFromOrg($orgId: ID!, $patientIds: [ID!]!) {
            removePatientsFromOrg(orgId: $orgId, patientIds: $patientIds) {
              ... on PatientIDs {
                patientIds
              }
              ... on PatientAccessList {
                patientAccess {
                  id
                  patient {
                    id
                  }
                }
              }
            }
          }
        `,
        variables: {
          patientIds: params.ids.map((id: string) => `${id}`),
          orgId: params.meta.orgId
        },
        parseResponse: (response) => {
          return {
            data: response.data.removePatientsFromOrg.patientAccess
              ? response.data.removePatientsFromOrg.patientAccess.map(
                  (pa: PatientAccess) => pa.patient.id
                )
              : response.data.removePatientsFromOrg.patientIds
          };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryPatient;
