import {
  Datagrid,
  TextField,
  Button,
  List,
  useUpdate,
  useNotify,
  useGetIdentity,
  Identifier,
  FunctionField,
  RaRecord
} from "react-admin";

import { Box } from "@mui/material";

import { RuneDateField } from "../../fields/RuneDateField";
import { NoResourceMessage } from "../../common/NoResourceMessage";
import { StudyDatagridHeader } from "../../common/StudyDataGrid/StudyDatagridHeader";
import { OrgTagBadge } from "./OrgTagBadge";
import iconCheckMarkGreen from "../../../shared/icon/checkmark-green.svg";
import { CreateOrgDialog } from "./OrgCreate";

import {
  ListContentHeader,
  ListContentTitle,
  ListActions
} from "../../common/ListContentHeader";

const NoOrgMessage = () => <NoResourceMessage>No orgs.</NoResourceMessage>;

const CreateOrgButton = () => {
  return <CreateOrgDialog></CreateOrgDialog>;
};

const OrgList = () => {
  const dateFmtOptions = { year: "numeric", month: "short", day: "numeric" };

  const groupedLabels = [
    {
      subLabels: [{ name: "Organization Name", sortable: true }]
    },
    { subLabels: [{ name: "Tags", sortable: true }] },
    { subLabels: [{ name: "ID", sortable: true }] },
    { subLabels: [{ name: "Date Created", sortable: true }] }
  ];

  const { data: user } = useGetIdentity();
  const defaultOrgId = user?.defaultMembership?.org?.id;
  const isSuperAdmin = user?.defaultMembership?.role.superAdmin;

  const orgRowClick = (id: Identifier) => {
    // only allow click through for current default org
    const isDefaultOrgRow = id === defaultOrgId;
    return isDefaultOrgRow ? `/Org/${id}/show` : false;
  };

  const orgRowStyle = (record: RaRecord) => {
    // only indicate clickability for current default org
    const isDefaultOrgRow = record.id === defaultOrgId;
    const cursorStyle = {
      cursor: isDefaultOrgRow ? "pointer" : "default"
    };
    return cursorStyle;
  };

  let queryResource = "UserOrg";
  if (isSuperAdmin) {
    queryResource = "Org";
  }

  return (
    <Box sx={{ padding: "8px 32px" }}>
      <ListContentHeader>
        <ListContentTitle>Organizations</ListContentTitle>
        {isSuperAdmin && (
          <ListActions>
            <CreateOrgButton />
          </ListActions>
        )}
      </ListContentHeader>

      <List resource={queryResource} perPage={50} actions={false}>
        <Datagrid
          rowClick={orgRowClick}
          rowStyle={orgRowStyle}
          empty={<NoOrgMessage />}
          header={<StudyDatagridHeader groupedLabels={groupedLabels} />}
          bulkActionButtons={false}
        >
          <TextField source="displayName" label="Organization Name" />
          <OrgTagBadge source="orgTags" label="Tags" />

          <TextField source="id" label="ID" />
          <RuneDateField
            source="created"
            label="Date Created"
            showTime={false}
            dateFmtOptions={dateFmtOptions}
          />
          <CurrentOrgField
            label="Current Org"
            source="defaultOrgId"
            sortable={false}
            defaultorg={defaultOrgId}
          />
        </Datagrid>
      </List>
    </Box>
  );
};

interface CurrentOrgFieldProps {
  defaultorg: string;
  label: string;
  sortable: boolean;
  source: string;
}

const CurrentOrgField = (props: CurrentOrgFieldProps) => {
  return (
    <FunctionField
      {...props}
      render={(record: RaRecord) => {
        const defaultOrgId = props.defaultorg;

        const rowItemOrgId = record.id;
        const isCurrentOrg = defaultOrgId === rowItemOrgId;

        return isCurrentOrg ? (
          <div
            style={{
              paddingLeft: "40px",
              height: "100%"
            }}
          >
            <img
              src={iconCheckMarkGreen}
              alt="checkmark"
              style={{
                height: "12px"
              }}
            />
          </div>
        ) : (
          <SetCurrentOrgButton newCurrentOrgId={rowItemOrgId} />
        );
      }}
    />
  );
};

interface SetCurrentOrgButtonProps {
  newCurrentOrgId: Identifier;
}

const SetCurrentOrgButton = ({ newCurrentOrgId }: SetCurrentOrgButtonProps) => {
  const [update] = useUpdate();
  const notify = useNotify();

  const handleClick = () => {
    update(
      "UserOrg",
      {
        data: {
          orgId: newCurrentOrgId
        }
      },
      {
        onSuccess() {
          // Reloading the page triggers the `beforeunload` event listener in LogOutOnTabClose,
          // which logs out super admins. To prevent this while still allowing the page to reload,
          // we store a flag that's used by LogOutOnTabClose.
          window.localStorage.setItem("reloadFlag", "true");
          // Force a page reload. There may very well be a better way, but currently have not found a way
          // to force useGetIdentity to fetch the refreshed data, so while setting a new current org worked,
          // clicking the Administration tab would result in the old default org being set.
          window.location.reload();
        },
        onError(error) {
          if (error instanceof Error) {
            notify(error.message, {
              type: "error"
            });
          } else {
            notify("Unknown error occurred. Unable to set current org.", {
              type: "error"
            });
          }
        }
      }
    );
  };

  return (
    <Button
      label="Set as Current"
      sx={{
        textTransform: "capitalize"
      }}
      onClick={handleClick}
    />
  );
};

export default OrgList;
