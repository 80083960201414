import { Amplify, Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { ClientMetaData } from "@aws-amplify/auth/lib-esm/types";
import gql from "graphql-tag";
import mixpanel from "mixpanel-browser";

import { USE_SSO } from "../../constants";

// https://runelabs.atlassian.net/browse/SW-2470
/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface AuthProviderOptions {
  authGroups?: string[];
  graphqlClient: any;
}

const defaultOptions = {
  authGroups: []
};

export class AuthProvider {
  public authGroups: string[];
  private graphqlClient: any;
  public currentUser: any;

  public constructor(options: AuthProviderOptions) {
    this.authGroups = options.authGroups || defaultOptions.authGroups;
    this.graphqlClient = options.graphqlClient;
    this.currentUser = null;
  }

  public login = ({
    username,
    password,
    clientMetadata
  }: Record<string, unknown>): Promise<CognitoUser | unknown> => {
    mixpanel.track("signIn", { username: username });
    const awsConfig = (window as any).Rune.Carrot.config.aws;

    if (username === "admin") {
      // Use SSO config (Super Admin user pool) for Amplify config
      Amplify.configure(awsConfig.sso.config);
      window.localStorage.setItem(USE_SSO, "true");

      return Amplify.Auth.federatedSignIn({ customProvider: "Duo" });
    }

    // Use SSO config (standard user pool) for Amplify config
    Amplify.configure(awsConfig.user.config);
    window.localStorage.setItem(USE_SSO, "false");

    return Auth.signIn(
      username as string,
      password as string,
      clientMetadata as ClientMetaData
    );
  };

  public logout = (): Promise<any> => {
    mixpanel.track("logout");
    this.currentUser = null;
    return Auth.signOut();
  };

  public checkAuth = async (): Promise<void> => {
    const session = await Amplify.Auth.currentSession().catch(() => {
      this.currentUser = null;
      // If there is no current session. Return a rejected promise,
      // and do not return an error message. This will prevent an
      // error message being displayed when a user first navigates
      // to the login page, but has not yet signed in.
      return Promise.reject({ message: false });
    });

    if (this.authGroups.length === 0) {
      return;
    }

    const userGroups = session.getAccessToken().decodePayload()[
      "cognito:groups"
    ];

    if (!userGroups) {
      this.currentUser = null;
      throw new Error("Unauthorized");
    }

    for (const group of userGroups) {
      if (this.authGroups.includes(group)) {
        return;
      }
    }

    this.currentUser = null;
    throw new Error("Unauthorized");
  };

  public checkError = (): Promise<void> => {
    return Promise.resolve();
  };

  public getPermissions = (): Promise<string[]> => {
    return Promise.resolve([]);
  };

  public getIdentity = async (): Promise<any> => {
    if (this.currentUser) {
      return this.currentUser;
    }

    const { data } = await this.graphqlClient.query({
      query: gql`
        query getUser {
          user {
            id
            name
            username
            email
            created
            defaultMembership {
              role {
                admin
                displayName
                superAdmin
                canSeePHI
              }
              org {
                id
                displayName
              }
            }
          }
        }
      `,
      variables: {}
    });
    this.currentUser = data.user;
    return this.currentUser;
  };
}
