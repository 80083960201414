import { TimeInterval, MILLI_SEC_PER_DAY } from "../../constants";

export function calculateAverageDataPerDay(
  timeInterval: TimeInterval,
  totalHours: number,
  projectStartDate: number,
  patientAddedToProjectDate: number
): number {
  let numDays = 1;
  const projectStartTime = new Date(projectStartDate).getTime();
  const patientAddedToProjectTime = new Date(
    patientAddedToProjectDate
  ).getTime();

  const numDaysSincePatientAddedToProject = Math.round(
    (Date.now() - patientAddedToProjectTime) / MILLI_SEC_PER_DAY
  );
  if (numDaysSincePatientAddedToProject < 1) {
    return 0;
  }

  switch (timeInterval) {
    case TimeInterval.PROJECT_ALL:
      numDays = Math.round((Date.now() - projectStartTime) / MILLI_SEC_PER_DAY);
      break;
    case TimeInterval.FOURTEEN_DAYS:
      numDays = 14;
      break;
    case TimeInterval.NINETY_DAYS:
      numDays = 90;
      break;
  }

  const numberOfDaysForDailyAverage = Math.min(
    numDays,
    numDaysSincePatientAddedToProject
  );
  return totalHours / numberOfDaysForDailyAverage;
}

export const isDataOutdated = (endTime: number, threshold: number): boolean => {
  const now = Date.now() / 1000;
  return !endTime || now - endTime > threshold;
};
