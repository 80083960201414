import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";

import { AggregatedMetric, QueryTypes } from "../../constants";
import { calculateAverageDataPerDay, isDataOutdated } from "./utilities";
import { DATA_AVAILABILITY_THRESHOLD_DAYS, SEC_PER_DAY } from "../../constants";

// https://runelabs.atlassian.net/browse/SW-2470
/* eslint-disable @typescript-eslint/no-explicit-any */

export type PatientMetricData = {
  id: string;
  metricCategoryTimeRanges: {
    metricCategory: string;
    startTime: number;
    endTime: number;
  }[];
  patient: {
    id: string;
    createdAt: string;
    identifiableInfo?: {
      realName: string;
      email: string;
    };
    appMode?: {
      id: string;
      displayName: string;
    };
  };
  [key: string]: any;
};

type Patient = {
  id: string;
  createdAt: string;
  realName: string;
  email: string;
  [key: string]: any;
  // include other properties as needed
};

const buildQueryProjectPatient = (
  fetchType: string,
  params: any
): BuildQueryResult => {
  const sortInput: any = {};
  const filterInput: any = {};
  let gqlQuery = `mutation($projectId: ID!) { \n`;

  switch (fetchType) {
    case QueryTypes.CREATE:
      if (
        params.data.status === "UNKNOWN_PATIENT" ||
        params.data.status === "PATIENT_NOT_FOUND"
      ) {
        // In this section, we either retrieve the patient id based on the device ID
        // or add it to the current org if it is not attached yet
        return {
          query: gql`
            mutation createPatientAccessByDevice(
              $input: CreatePatientAccessInput!
            ) {
              createPatientAccess(input: $input) {
                patientAccess {
                  id
                  org {
                    id
                  }
                  patient {
                    id
                    codeName
                    deviceList {
                      devices {
                        deviceShortId
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: {
            input: {
              deviceId: params.data.device_id
            }
          },
          parseResponse: (response) => {
            return {
              data: response.data.createPatientAccess.patientAccess
            };
          }
        };
      } else if (
        params.data.status === "PATIENT_FOUND" ||
        params.data.status === "PATIENT_NOT_ADDED"
      ) {
        return {
          query: gql`
            mutation ($input: CreateProjectPatientInput!) {
              addPatientToProject(input: $input) {
                projectPatient {
                  patient {
                    codeName
                    id
                  }
                  codeName
                  createdAt
                  updatedAt
                  createdBy
                  updatedBy
                }
              }
            }
          `,
          variables: {
            input: {
              codeName: params.data.patient_codename,
              patientId: params.data.patient_id,
              projectId: params.data.project_id
            }
          },
          parseResponse: (response) => {
            const data = response.data.addPatientToProject.projectPatient;
            data.id =
              response.data.addPatientToProject.projectPatient.patient.id;
            return {
              data: data
            };
          }
        };
      }
      return {
        query: gql``,
        variables: {
          orgId: params.org_id,
          deviceId: params.device_id
        },
        parseResponse: (response) => {
          return {
            data: response.data,
            total: response.data.length
          };
        }
      };
    case QueryTypes.UPDATE:
      return {
        query: gql`
          mutation ($input: UpdateProjectPatientInput!) {
            updateProjectPatient(input: $input) {
              projectPatient {
                patient {
                  codeName
                  id
                }
                codeName
              }
            }
          }
        `,
        variables: {
          input: {
            codeName: params.data.codeName,
            patientId: params.data.patient.id,
            projectId: params.data.projectId
          }
        },
        parseResponse: (response) => {
          const projectPatient =
            response.data?.updateProjectPatient?.projectPatient;
          const id = `${params.data.projectId}${projectPatient.patient.id}`;
          response.data.id = id;
          return {
            data: response.data,
            total: response.data.length
          };
        }
      };

    case QueryTypes.GET_MANY_REFERENCE:
      if (params.filter.patientListV2) {
        return {
          query: gql`
            query getProject(
              $projectId: ID,
              $metricCategories: [MetricCategoryEnum]
            ) {
              project(id: $projectId) {
                id
                startedAt
                projectPatientList {
                  projectPatients {
                    patient {
                      id
                      striveUserId
                      createdAt
                      ${
                        params.filter.enablePiiToggle
                          ? "identifiableInfo { realName email }"
                          : ""
                      }
                      appMode {
                        id
                        displayName
                      }
                    }
                    codeName
                    metricCategoryTimeRanges(metricCategories: $metricCategories) {
                      metricCategory
                      startTime
                      endTime
                    }
                  }
                  pageInfo {
                    codeNameEndCursor
                    metricsEndCursor
                  }
                }
              }
            }
          `,
          variables: {
            projectId: params.id,
            timeInterval: params.filter.dataAvailability,
            metricCategories: params.filter.metricCategories
          },
          parseResponse: (response) => {
            const dataAvailabilityThresholdDaysInSeconds =
              DATA_AVAILABILITY_THRESHOLD_DAYS * SEC_PER_DAY;
            const projectPatients =
              response.data.project.projectPatientList.projectPatients;

            const metricsData = projectPatients.map(
              (projectPatient: PatientMetricData) => {
                const res: any = {
                  ...projectPatient,
                  id: projectPatient.patient.id,
                  createdAt: projectPatient.patient.createdAt,
                  realName: projectPatient.patient.identifiableInfo?.realName,
                  email: projectPatient.patient.identifiableInfo?.email,
                  appMode: projectPatient.patient.appMode?.displayName
                };

                for (const metricCategoryTimeRange of projectPatient.metricCategoryTimeRanges) {
                  res[metricCategoryTimeRange.metricCategory] =
                    metricCategoryTimeRange.endTime;
                }

                return res;
              }
            );

            const totalPatients = metricsData.length;
            const categoryCounts: { [key: string]: number } = {};
            const noDataForTenDaysPatients = new Set();

            for (const metricCategory of params.filter.metricCategories) {
              categoryCounts[metricCategory] = 0;
            }

            metricsData.forEach((patient: PatientMetricData) => {
              let noDataForAnyCategory = false;

              params.filter.metricCategories.forEach(
                (metricCategory: string) => {
                  const endTime = patient[metricCategory];
                  if (
                    isDataOutdated(
                      endTime,
                      dataAvailabilityThresholdDaysInSeconds
                    )
                  ) {
                    categoryCounts[metricCategory]++;
                    noDataForTenDaysPatients.add(patient.id);
                    noDataForAnyCategory = true;
                  }
                }
              );

              if (noDataForAnyCategory) {
                noDataForTenDaysPatients.add(patient.id);
              }
            });

            const filteredPatients = metricsData.filter((patient: Patient) => {
              if (params.filter.qcMetricCategory === "ANY") {
                return noDataForTenDaysPatients.has(patient.id);
              } else {
                const endTime =
                  patient[params.filter.qcMetricCategory as string];
                return isDataOutdated(
                  endTime,
                  dataAvailabilityThresholdDaysInSeconds
                );
              }
            });

            return {
              data: filteredPatients,
              total: totalPatients,
              categoryCounts: categoryCounts,
              noDataForAnyCategoryCount: noDataForTenDaysPatients.size
            };
          }
        };
      } else {
        if (params.sort.field !== "id" && params.sort.field !== "codeName") {
          const [sortDataType, ...rest] = params.sort.field.split("@");
          let sortType = rest[0];
          if (sortType === "AVG_PER_DAY")
            // "AVG_PER_DAY" is computed on the frontend based on "TOTAL_HOURS"
            sortType = "TOTAL_HOURS";
          sortInput["metricDataType"] = sortDataType;
          sortInput["metricType"] = sortType;
        }
        sortInput["direction"] = params.sort.order;

        filterInput["timeInterval"] = params.filter.dataAvailability;

        if (params.filter.qcMetric !== 0) {
          switch (params.filter.qcMetric) {
            case 1:
              filterInput["aggregatedMetric"] =
                AggregatedMetric.NO_RECENT_SYMPTOM_DATA;
              break;
            case 2:
              filterInput["aggregatedMetric"] =
                AggregatedMetric.NO_RECENT_HR_DATA;
              break;
            case 3:
              filterInput["aggregatedMetric"] =
                AggregatedMetric.NO_RECENT_HR_AND_SYMPTOM_DATA;
              break;
          }
        }

        return {
          query: gql`
          query getProject(
            $projectId: ID
            $sortInput: SortInput
            $filterInput: FilterInput
            $timeInterval: MetricTimeInterval
          ) {
            project(id: $projectId) {
              id
              startedAt
              projectPatientList(
                sortInput: $sortInput
                filterInput: $filterInput
              ) {
                projectPatients {
                  patient {
                    id
                    striveUserId
                    createdAt
                    ${
                      params.filter.enablePiiToggle
                        ? "identifiableInfo { realName email }"
                        : ""
                    }
                  }
                  codeName
                  metricList(timeInterval: $timeInterval) {
                    metrics {
                      id
                      updatedAt
                      createdAt
                      type
                      dataType
                      value
                      timeInterval
                    }
                  }
                }
                pageInfo {
                  codeNameEndCursor
                  metricsEndCursor
                }
              }
            }
          }
        `,
          variables: {
            projectId: params.id,
            timeInterval: params.filter.dataAvailability,
            filterInput: filterInput,
            sortInput: sortInput
          },
          parseResponse: (response) => {
            const data =
              response.data.project.projectPatientList.projectPatients.map(
                (projectPatient: {
                  metricList: any;
                  patient: {
                    id: any;
                    createdAt: any;
                    identifiableInfo: { realName: string; email: string };
                  };
                }) => {
                  const res: any = {
                    ...projectPatient,
                    id: projectPatient.patient.id,
                    createdAt: projectPatient.patient.createdAt,
                    realName: projectPatient.patient.identifiableInfo?.realName,
                    email: projectPatient.patient.identifiableInfo?.email
                  };

                  projectPatient.metricList.metrics.forEach(
                    (metric: {
                      dataType: string;
                      type: string;
                      value: any;
                    }) => {
                      res[metric.dataType + "@" + metric.type] = metric.value;
                    }
                  );

                  // Avg/Day computation for Tremor/Dyskinesia
                  if ("APPLEWATCH_SYMPTOM@TOTAL_HOURS" in res) {
                    res["APPLEWATCH_SYMPTOM@AVG_PER_DAY"] =
                      calculateAverageDataPerDay(
                        params.filter.dataAvailability,
                        res["APPLEWATCH_SYMPTOM@TOTAL_HOURS"],
                        params.filter.projectStartDate,
                        res.createdAt
                      );
                  }
                  return res;
                }
              );

            return {
              data: data,
              total:
                response.data.project.projectPatientList.projectPatients.length
            };
          }
        };
      }

    case QueryTypes.DELETE_MANY:
      params.ids.forEach((id: string, index: number) => {
        gqlQuery +=
          "aliasDelete" +
          index +
          ': removePatientFromProject(projectId: $projectId, patientId: "' +
          id +
          '") { status } \n';
      });
      gqlQuery += "}\n";

      return {
        query: gql(gqlQuery),
        variables: {
          projectId: params.meta.projectId
        },
        parseResponse: () => {
          return { data: [] };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryProjectPatient;
