import React, { useEffect, useRef, useState } from "react";
import { ProjectDisplayConfig } from "../resources/project/ProjectShow/ProjectDisplayConfigurationDialog";
import { useDataProvider, useRecordContext } from "react-admin";
import {
  getDataAvailabilityConfiguration,
  getMobilityStreamParams,
  getSleepStageStreamParams,
  getTremorStreamParams
} from "src/providers/streams/runeStreamsUtils";
import { get } from "lodash";
import moment from "moment-timezone";

import { COLORS } from "../common/colors";

// Define the types for your data and events
export type DataType = {
  date: string;
  patientId: string;
  tremor_dyskinesia?: number;
  mobility?: number;
  sleep?: number;
  medication?: Record<string, number>;
};

const getColor = (
  value: number,
  threshold: number,
  date: string,
  onboardedDate: Date | null,
  key: keyof DataType,
  projectDisplayConfig: ProjectDisplayConfig
): string => {
  const colorMode: "YELLOW_RED" | "CLINICIAN_CALENDAR" = projectDisplayConfig
    .visualization.highlightMissingData
    ? "YELLOW_RED"
    : "CLINICIAN_CALENDAR";

  switch (colorMode) {
    case "YELLOW_RED":
      if (onboardedDate && new Date(date) < onboardedDate) {
        return COLORS.GREY_400;
      }
      if (value > threshold || (threshold === undefined && value > 0))
        return COLORS.PRIMARY_300;
      if (value > 0) return COLORS.YELLOW_200;
      return COLORS.RED_200;
    case "CLINICIAN_CALENDAR":
      if (value > 0) {
        if (key === "tremor_dyskinesia") {
          return COLORS.PRIMARY_1200;
        }
        if (key === "mobility") {
          return COLORS.PRIMARY_700;
        }
        if (key === "sleep") {
          return COLORS.PRIMARY_400;
        }
        console.log(`the datatype ${key} is not handled`);
        return COLORS.RED_200;
      }
      return COLORS.GREY_200;
  }
};

const isMonday = (dateString: string): boolean => {
  const date = new Date(dateString);
  return date.getDay() === 1;
};

const isFirstOfMonth = (dateString: string): boolean => {
  const date = new Date(dateString);
  return date.getDate() === 1;
};

const getMonthLabelPositions = (data: DataType[]) => {
  const labels: { text: string; position: number }[] = [];
  let lastMonth = "";
  data.forEach((entry, index) => {
    const month = new Date(entry.date).toLocaleString("default", {
      month: "short"
    });
    if (month !== lastMonth) {
      labels.push({ text: month, position: index });
      lastMonth = month;
    }
  });
  return labels;
};

const getLegendText = (key: keyof DataType): string => {
  const legends: { [key in keyof DataType]?: string } = {
    tremor_dyskinesia: "T&D",
    mobility: "Mobility",
    sleep: "Sleep",
    medication: "Meds"
    // Add other keys and their corresponding legends here
  };
  return legends[key] || key;
};

const getTooltipText = (entry: DataType, key: keyof DataType): string => {
  if (key === "medication" && entry.medication) {

    const totalMedicationCount = Object.values(entry.medication).reduce(
      (sum, count) => sum + count,
      0
    );
    return `<b>${new Date(entry.date).toLocaleDateString()}</b><br />Total Medication: ${totalMedicationCount}`;

    // We will want to display the medication details in the tooltip
    // However, this gets messy because custom meds are not accessible to users without PII access
    // We will revisit this tooltip once the custom medicaiton are available for all users
    // const medicationDetails = Object.entries(entry.medication)
    //   .map(([med, count]) => `${med}: ${count}`)
    //   .join("<br />");
    // return `<b>${new Date(entry.date).toLocaleDateString()}</b><br />${medicationDetails}`;
  }
  return `<b>${new Date(
    entry.date
  ).toLocaleDateString()}</b><br />${getLegendText(key)}: ${
    isNaN(entry[key] as number) || entry[key] === 0
      ? "None"
      : `${Math.round(entry[key] as number)} hours`
  }`;
};

const DataAvailabilityHeatmapCalendarField = (props: {
  projectDisplayConfig: ProjectDisplayConfig;
  dataAvailability: string;
  projectStart: Date;
}) => {
  const { projectDisplayConfig, dataAvailability } = props;
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const patientId = get(record, "id");
  const patientOnboardedDate = get(record, "createdAt")
    ? new Date(get(record, "createdAt") * 1000)
    : null;
  const [tooltip, setTooltip] = useState<{
    text: string;
    x: number;
    y: number;
  } | null>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const [availabilityData, setAvailabilityData] = useState<any[]>([]);
  const dataKeys: (keyof DataType)[] = [];

  Object.keys(projectDisplayConfig["dataTypes"]).forEach((key) => {
    const configKey = key as keyof ProjectDisplayConfig["dataTypes"];
    if (projectDisplayConfig["dataTypes"][configKey].enabled) {
      dataKeys.push(configKey);
    }
  });

  const fixedOrder: (keyof DataType)[] = [
    "tremor_dyskinesia",
    "mobility",
    "sleep",
    "medication"
  ];
  const orderedDataKeys = fixedOrder.filter((key) => dataKeys.includes(key));

  const monthLabels = getMonthLabelPositions(availabilityData);
  const handleMouseEnter = (
    e: React.MouseEvent,
    key: keyof DataType,
    entry: DataType
  ) => {
    if (svgRef.current) {
      const rect = svgRef.current.getBoundingClientRect();
      setTooltip({
        text: getTooltipText(entry, key),
        x: e.clientX - rect.left + 10,
        y: e.clientY - rect.top + 10
      });
    }
  };

  const cellSize = 15; // Size of each cell in the grid
  const padding = 5;
  const HORIZONTAL_OFFSET = 110;

  useEffect(() => {
    const enabledCategories = (
      Object.keys(
        projectDisplayConfig["dataTypes"]
      ) as (keyof ProjectDisplayConfig["dataTypes"])[]
    ).filter(
      (key) =>
        projectDisplayConfig["dataTypes"][
          key as keyof ProjectDisplayConfig["dataTypes"]
        ].enabled
    );

    setAvailabilityData([]);

    const fetchStreamData = async () => {
      const streamParamsFunctions = {
        tremor_dyskinesia: getTremorStreamParams,
        mobility: getMobilityStreamParams,
        sleep: getSleepStageStreamParams
      };

      const { startDate, aggregateWindowResolution } =
        getDataAvailabilityConfiguration(
          props.dataAvailability,
          props.projectStart
        );

      // Trying to fix some timezone things (not fully functional yet)
      // get browser time zone for now
      const browserTimezone = moment.tz.guess();
      const dateString = startDate.toLocaleDateString("en-US", {
        timeZone: browserTimezone
      });
      const midnightUTCDate = new Date(dateString);
      midnightUTCDate.setHours(17, 0, 0, 0);

      const startDateTimestamp = Math.floor(midnightUTCDate.getTime() / 1000);
      const endDateTimestamp = Date.now() / 1000;

      const startTime = startDateTimestamp;
      const endTime = endDateTimestamp;

      const streamIdsPromises = enabledCategories
      .filter(
        (key) => ["tremor_dyskinesia", "mobility", "sleep"].includes(key))
      .map((category) => {
        if (
          streamParamsFunctions[category as keyof typeof streamParamsFunctions]
        ) {
          const params = streamParamsFunctions[
            category as keyof typeof streamParamsFunctions
          ](patientId.toString());
          return dataProvider
            .getStreamIds(params)
            .then((streamIds: string[]) => {
              return { category, streamIds }; // Return an object with both category and streamIds
            });
        }
        return Promise.resolve(null); // Return null for categories without a function mapping
      });

      const streamIdsResults = await Promise.all(streamIdsPromises);

      const streamsDataPromises: {
        category: string;
        promise: Promise<any>;
      }[] = [];

      // Retrieve data for each Stream ID using getAggregateWindow
      for (const streamIdsByCategory of streamIdsResults) {
        if (
          streamIdsByCategory["category"] === "tremor_dyskinesia" ||
          streamIdsByCategory["category"] === "mobility" ||
          streamIdsByCategory["category"] === "sleep"
        ) {
          // We check that there is only one stream ID
          if (streamIdsByCategory["streamIds"]["data"].length > 1) {
            console.log("streamIdsByCategory", streamIdsByCategory);
            console.error(
              `Expected one stream ID MAXIMUM for ${streamIdsByCategory["category"]}`
            );
            continue;
          }
          if (streamIdsByCategory["streamIds"]["data"].length === 0) {
            continue;
          }
          const promise = dataProvider.getStreamAggregateWindow(
            streamIdsByCategory["streamIds"]["data"][0]["id"],
            {
              start_time: JSON.stringify(startTime),
              end_time: endTime,
              resolution: JSON.stringify(aggregateWindowResolution),
              aggregate_function: "sum",
              // TODO: SW-3563: Timezone are currently not handled correctly.
              // It should be taking into account the timezone of the user instead of being the browser timezone.
              // A full revamp/revisit/refactor of the timezone management is expected
              timezone_name: browserTimezone
            }
          );
          streamsDataPromises.push({
            category: streamIdsByCategory["category"],
            promise
          });
        }
      }

      const streamsDataResults = await Promise.all(
        streamsDataPromises.map((p) => p.promise)
      );

      // Initialize availabilityData based on the retrieved data from streams
      const nanoSecondsPerHour = 1e9 * 60 * 60;
      const newAvailabilityData = streamsDataResults
        .map((streamData, index) => {
          const category = streamsDataPromises[index].category;

          const { time, duration_sum } = streamData.json.data;

          return time.map((timestamp: number, index: number) => ({
            patientId: patientId,
            date: new Date(timestamp * 1000).toISOString().split("T")[0], // Convert UNIX timestamp to ISO date string
            [category]: duration_sum[index] / nanoSecondsPerHour
          }));
        })
        .flat();

        if (projectDisplayConfig["dataTypes"]["medication"]?.enabled) {
          const medicationData = await dataProvider.getManyReference("PatientEvent", {
            target: "patient",
            id: patientId,
            pagination: {
              page: 1,
              perPage: 100
            },
            sort: {
              field: "createdAt",
              order: "desc"
            },
            filter: {}
          });
  
          const filteredMedicationData = medicationData.data.filter(
            (event) => event.classification?.category === "medication"
          );
  
          filteredMedicationData.forEach((medicationEvent) => {
            const date = new Date(medicationEvent.duration.startTime * 1000).toISOString().split("T")[0];

            let medicationDisplayName = medicationEvent.displayNote;

            if (medicationEvent.displayName == "Custom Event" && medicationEvent.customDetail?.displayName)
              medicationDisplayName = medicationEvent.customDetail.displayName;
    
            if (medicationEvent.method == 'autolog')
              medicationDisplayName = `${medicationDisplayName} (A)`;

            const existingEntry = newAvailabilityData.find(entry => entry.date === date);
            if (existingEntry) {
              if (!existingEntry.medication) {
                existingEntry.medication = {};
              }
              medicationDisplayName in existingEntry.medication ? 
                existingEntry.medication[medicationDisplayName] += 1 :
                existingEntry.medication[medicationDisplayName] = 1;
            } else {
              newAvailabilityData.push({
                patientId: patientId,
                date: date,
                medication: {
                  [medicationDisplayName]: 1,
                }
              });
            }
          });
        }


      // Aggregate data by date
      const dataByDate = newAvailabilityData.reduce((acc, entry) => {
        const { date, patientId, ...rest } = entry;
        if (!acc[date]) {
          acc[date] = { date, patientId }; // Initialize with date and patientId
        }
        // Merge entries by category for the same date
        Object.assign(acc[date], rest);
        return acc;
      }, {});

      // Convert the object back to array
      const aggregatedData: DataType[] = Object.values(dataByDate);

      // Sort the array by date
      aggregatedData.sort(
        (a: DataType, b: DataType) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );

      setAvailabilityData(aggregatedData);
    };

    fetchStreamData();
  }, [dataProvider, projectDisplayConfig, patientId, dataAvailability]);

  return (
    <div style={{ position: "relative" }}>
      <svg
        ref={svgRef}
        width={availabilityData.length * cellSize + HORIZONTAL_OFFSET + 20}
        height={dataKeys.length * (cellSize + padding) + 20}
      >
        {monthLabels.map((label) => (
          <text
            key={`${patientId}-month-${label.text}`}
            x={label.position * cellSize + HORIZONTAL_OFFSET}
            y={15}
            fill="#666"
          >
            {label.text}
          </text>
        ))}
        {orderedDataKeys.map((key, rowIndex) => (
          <React.Fragment key={key + rowIndex}>
            <g
              transform={`translate(50, ${
                rowIndex * (cellSize + padding) + 17
              })`}
            >
              <text x="0" y="15" fontSize="12" fill="#000">
                {getLegendText(key)}
              </text>
            </g>
            {availabilityData.map((entry, colIndex) => (
              <g
                key={`${patientId}-${key}-${entry.date}-${rowIndex}-${colIndex}`}
              >
                <rect
                  x={colIndex * cellSize + HORIZONTAL_OFFSET}
                  y={rowIndex * (cellSize + padding) + 20}
                  width={cellSize}
                  height={cellSize}
                  fill={getColor(
                    key === "medication"
                      ? entry[key] 
                        ? Object.values(entry[key] as Record<string, number>).reduce((a, b) => a + b, 0)
                        : 0
                      : entry[key] as number,
                    projectDisplayConfig["dataTypes"][
                      key as keyof ProjectDisplayConfig["dataTypes"]
                    ].threshold as number,
                    entry.date,
                    patientOnboardedDate,
                    key,
                    projectDisplayConfig
                  )}
                  stroke="white"
                  onMouseEnter={(e) => handleMouseEnter(e, key, entry)}
                  onMouseLeave={() => setTooltip(null)}
                  onClick={() =>
                    (window.location.href = `/patient/${
                      entry.patientId
                    }/show/3?timestamp=${new Date(entry.date).toISOString()}`)
                  }
                />
                {isFirstOfMonth(entry.date) && (
                  <line
                    x1={colIndex * cellSize + HORIZONTAL_OFFSET}
                    y1={rowIndex * (cellSize + padding) + padding * 4}
                    x2={colIndex * cellSize + HORIZONTAL_OFFSET}
                    y2={
                      rowIndex * (cellSize + padding) + padding * 4 + cellSize
                    }
                    stroke="black"
                    strokeWidth={2}
                  />
                )}
                {isMonday(entry.date) && (
                  <line
                    x1={colIndex * cellSize + HORIZONTAL_OFFSET}
                    y1={rowIndex * (cellSize + padding) + padding * 4}
                    x2={colIndex * cellSize + HORIZONTAL_OFFSET}
                    y2={
                      rowIndex * (cellSize + padding) + padding * 4 + cellSize
                    }
                    stroke="gray"
                    strokeWidth={1}
                  />
                )}
              </g>
            ))}
          </React.Fragment>
        ))}
      </svg>
      {tooltip && (
        <div
          style={{
            position: "absolute",
            left: tooltip.x,
            top: tooltip.y - 50,
            padding: "5px",
            border: "1px solid gray",
            backgroundColor: "white"
          }}
          dangerouslySetInnerHTML={{ __html: tooltip.text }}
        />
      )}
    </div>
  );
};

export default DataAvailabilityHeatmapCalendarField;
