interface CustomPercentageBarChartTooltipProps {
  active?: boolean;
  payload?: payloadType[];
  label?: number;
}
type payloadType = {
  color: string | undefined;
  value: number;
  name: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  payload: any;
};

export const CustomPercentageBarChartTooltip = ({
  active,
  payload
}: CustomPercentageBarChartTooltipProps) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short"
  };

  if (active && payload && payload.length) {
    console.log("payload", payload);
    return (
      <div className="custom-tooltip">
        <p className="label">
          <b>{`${payload[0].payload.timestamp.toLocaleString(
            "en-US",
            options
          )} `}</b>
          <br />

          {payload.map((entry, index) => (
            <div key={index}>
              <span style={{ color: entry.color }}>{entry.name}: </span>
              {`${parseFloat(entry.value * 100 + "").toFixed(0)}%`}
            </div>
          ))}
          <br />
        </p>
      </div>
    );
  }
  return null;
};
