import { styled, Tooltip } from "@mui/material";
import { runeTheme } from "../../../common/RuneTheme";

import mixpanel from "mixpanel-browser";
import { useFlags } from "src/clients/launchDarkly";
import { ProjectDisplayConfig } from "./ProjectDisplayConfigurationDialog";
import { DATA_AVAILABILITY_THRESHOLD_DAYS } from "../../../../constants";

const AggregatedMetricsContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  padding: "16px 16px",
  gap: "16px"
});

const AggregatedMetricContainer = styled("div")({
  display: "flex",
  boxSizing: "border-box",
  padding: "8px 16px",
  gap: "16px",
  maxWidth: "280px",
  height: "100px",

  border: "none",
  background: "white",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  cursor: "pointer",
  color: runeTheme.palette.primary.main,
  "&.selected": {
    background: "#3338B2",
    color: "white",
    boxShadow:
      "0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);"
  }
});

//box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.2);

const AggregatedMetricValue = styled("div")({
  fontFamily: "'Work Sans'",
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "72px",
  height: "84px",
  lineHeight: "84px",
  letterSpacing: "-0.03em",
  order: "1"
});
const AggregatedMetricLabel = styled("div")({
  fontFamily: "'Work Sans'",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  height: "19px",
  lineHeight: "19px",
  letterSpacing: "-0.03em",
  order: "2"
});

interface AggregatedMetricProps {
  tooltip: string;
  onClick: () => void;
  isSelected: boolean;
  value: number | undefined;
  label: string;
  index: number;
}

const AggregatedMetric = (props: AggregatedMetricProps) => {
  return (
    <Tooltip title={props.tooltip} arrow>
      <AggregatedMetricContainer
        onClick={props.onClick}
        className={props.isSelected ? "selected" : ""}
      >
        <AggregatedMetricValue>{props.value}</AggregatedMetricValue>
        <AggregatedMetricLabel>
          {props.label}
          {props.label === "Total Patients" ? (
            ""
          ) : (
            <>
              <br /> for {DATA_AVAILABILITY_THRESHOLD_DAYS} days
            </>
          )}
        </AggregatedMetricLabel>
      </AggregatedMetricContainer>
    </Tooltip>
  );
};

interface AggregatedMetricsProps {
  metrics?: {
    totalPatientCount: number;
    patientsWithoutSymptomDataCount: number;
    patientsWithoutHeartRateDataCount: number;
    patientsWithoutHeartRateAndSymptomDataCount: number;
  };
  qcMetricSelectedIndex: number;
  setQcMetricSelectedIndex: (index: number) => void;
  setQcMetricSelectedCategory?: (category: string) => void; // Only used for v2
  projectDisplayConfig?: ProjectDisplayConfig;
  dataCategoryCounts?: {
    totalPatientCount: number;
    categoryCounts: { [key: string]: number };
    noDataForAnyCategoryCount: number;
  };
}

const AggregatedMetrics = (props: AggregatedMetricsProps) => {
  const { patientListV2 } = useFlags();

  const handleAggregatedMetricSelection =
    (index: number, category: string) => () => {
      mixpanel.track("AggregatedMetricSelection", { index: index });
      props.setQcMetricSelectedIndex(index);
      if (patientListV2) {
        props.setQcMetricSelectedCategory?.(category);
      }
    };

  const generateLabel = (category: string) => {
    if (category === "ALL_PATIENTS") {
      return "Total Patients";
    }
    if (category === "TREMOR_DYSKINESIA") {
      return "No MM4PD";
    }
    if (category === "MOBILITY") {
      return "No Mobility";
    }
    if (category === "SLEEP") {
      return "No Sleep";
    }
    if (category === "ANY") {
      return "Any missing data";
    }
    return category;
  };

  const metricData = [];

  if (!patientListV2) {
    const { metrics } = props;
    if (!metrics) {
      return <></>;
    }

    metricData.push({
      value: metrics.totalPatientCount,
      category: "ALL_PATIENTS",
      label: "Total Patients",
      tooltip: "All patients",
      index: 0
    });
    metricData.push({
      value: metrics.patientsWithoutSymptomDataCount,
      category: "TREMOR_DYSKINESIA",
      label: "No MM4PD Data",
      tooltip:
        "Patients with no Tremor & Dyskinesia data uploaded in the last three days.",
      index: 1
    });
    metricData.push({
      value: metrics.patientsWithoutHeartRateDataCount,
      category: "HK",
      label: "No HK Data",
      tooltip:
        "Patients with no Apple HealthKit data uploaded in the last three days.",
      index: 2
    });
    metricData.push({
      value: metrics.patientsWithoutHeartRateAndSymptomDataCount,
      category: "MM4PD_HK",
      label: "No MM4PD and HK Data",
      tooltip:
        "Patients with no Tremor & Dyskinesia data and no Apple HealthKit data uploaded in the last three days.",
      index: 3
    });
  } else {
    metricData.push({
      value: props.dataCategoryCounts?.totalPatientCount,
      category: "ALL_PATIENTS",
      label: "Total Patients",
      tooltip: "All patients",
      index: 0
    });

    if (props.projectDisplayConfig?.dataTypes.tremor_dyskinesia.enabled) {
      const count =
        props.dataCategoryCounts?.categoryCounts["TREMOR_DYSKINESIA"];
      metricData.push({
        value: count,
        category: "TREMOR_DYSKINESIA",
        label: generateLabel("TREMOR_DYSKINESIA"),
        tooltip: `Patients with no MM4PD data in the last ${DATA_AVAILABILITY_THRESHOLD_DAYS} days.`,
        index: metricData.length
      });
    }
    if (props.projectDisplayConfig?.dataTypes.mobility.enabled) {
      const count = props.dataCategoryCounts?.categoryCounts["MOBILITY"];
      metricData.push({
        value: count,
        category: "MOBILITY",
        label: generateLabel("MOBILITY"),
        tooltip: `Patients with no Mobility data in the last ${DATA_AVAILABILITY_THRESHOLD_DAYS} days.`,
        index: metricData.length
      });
    }
    if (props.projectDisplayConfig?.dataTypes.sleep.enabled) {
      const count = props.dataCategoryCounts?.categoryCounts["SLEEP"];
      metricData.push({
        value: count,
        category: "SLEEP",
        label: generateLabel("SLEEP"),
        tooltip: `Patients with no Sleep data in the last ${DATA_AVAILABILITY_THRESHOLD_DAYS} days.`,
        index: metricData.length
      });
    }
  }

  return (
    <AggregatedMetricsContainer>
      {metricData.map((metric) => (
        <AggregatedMetric
          key={metric.index}
          {...metric}
          onClick={handleAggregatedMetricSelection(
            metric.index,
            metric.category
          )}
          isSelected={props.qcMetricSelectedIndex === metric.index}
        />
      ))}
    </AggregatedMetricsContainer>
  );
};

export default AggregatedMetrics;
