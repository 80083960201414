import { Box, Typography } from "@mui/material";
import { Legend } from "recharts";

interface PercentageBarChartLegendProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  handleLegendClick: any;
  title: string;
  subTitle: string;
  payload?: any;
}

export const PercentageBarChartLegend = (
  props: PercentageBarChartLegendProps
) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTop: "1px solid #EFEFEF",
        borderBottom: "1px solid #EFEFEF",
        paddingTop: "4px",
        paddingBottom: "4px"
      }}
    >
      <Typography
        style={{
          flex: "7%",
          color: "var(--black, #000)",
          fontFamily: "Work Sans",
          fontSize: 24,
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "110%",
          letterSpacing: "-0.24px"
        }}
      >
        {props.title}
      </Typography>

      <Typography
        style={{
          flex: "20%",
          color: "var(--grey-grey-600, #939393)",
          fontFamily: "Work Sans",
          fontSize: 12,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "150%",
          letterSpacing: "0.12px"
        }}
      >
        {props.subTitle}
      </Typography>

      <Box sx={{ flex: "73%", display: "flex", justifyContent: "left" }}>
        <Legend
          payload={props.payload}
          iconType="plainline"
          onClick={props.handleLegendClick}
          wrapperStyle={{
            position: "inherit",
            color: "var(--grey-grey-700, #777)",
            fontSize: 12,
            fontFamily: "IBM Plex Sans",
            fontStyle: "normal",
            fontWeight: 400
          }}
        />
      </Box>
    </Box>
  );
};
