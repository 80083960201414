import { useRecordContext } from "react-admin";
import { Typography } from "@mui/material";

import get from "lodash/get";
import { runeTheme } from "../common/RuneTheme";

export interface RuneDurationFieldProps {
  source: string;
  label: string;
  inputUnit: "HOUR" | "SECOND";
  outputFormat: "00d 00h 00m" | "00m 00w 00d" | "00h 00m";
}

export const RuneDurationField = (props: RuneDurationFieldProps) => {
  const record = useRecordContext();
  let value = get(record, props.source);

  if (typeof value === "undefined") value = 0;

  // We base our computation on the number of minutes
  let totalMinutes = 0;
  switch (props.inputUnit) {
    case "HOUR":
      totalMinutes = value * 60;
      break;
    case "SECOND":
      totalMinutes = value / 60;
      break;
  }

  // let displayMonth = 0;
  // let displayWeeks = 0;
  // let displayDays = 0;
  let displayHours = 0;
  let displayMinutes = 0;

  switch (props.outputFormat) {
    case "00h 00m":
      displayHours = Math.floor(totalMinutes / 60);
      displayMinutes = Math.round(totalMinutes % 60);
      break;
    default:
      // TODO: handle '00d 00h 00m' and '00m 00w 00d'
      break;
  }

  switch (props.outputFormat) {
    case "00h 00m":
      return (
        <>
          <Typography
            variant="body2"
            component="span"
            sx={{
              color: displayHours
                ? runeTheme.palette.dateTime.primary
                : runeTheme.palette.dateTime.secondary
            }}
          >
            {displayHours}h
          </Typography>

          <Typography
            variant="body2"
            component="span"
            sx={{
              color:
                displayHours || displayMinutes
                  ? runeTheme.palette.dateTime.primary
                  : runeTheme.palette.dateTime.secondary
            }}
          >
            {displayMinutes}m
          </Typography>
        </>
      );
  }

  return <></>;
};
