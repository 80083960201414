import { Box } from "@mui/material";
import moment from "moment-timezone";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { PercentageBarChartLegend } from "./PercentageBarChartLegend";
import { CustomPercentageBarChartTooltip } from "./PercentageBarChartTooltip";

interface PercentageBarChartProps {
  subTitle: string;
  title: string;
  seriesColor(dataKey: string): string | undefined;
  visibleSeries: { [key: string]: boolean };
  /* eslint-disable @typescript-eslint/no-explicit-any */
  handleLegendClick: any;
  data: {
    timestamp: number;
    [key: string]: number | string | Date;
  }[];
}

export const PercentageBarChart = (props: PercentageBarChartProps) => {
  const hourlyTimestamps = props.data
    .filter((point) => moment(point.timestamp).minutes() === 0)
    .map((point) => point.timestamp);

  return (
    <Box width="100%" sx={{ background: "#FFFFFF" }}>
      <ResponsiveContainer width="100%" height={315}>
        <BarChart
          data={props.data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5
          }}
          barGap={0}
          barCategoryGap={0}
        >
          <XAxis
            dataKey="timestamp"
            tickFormatter={(value: number) => moment(value).format("HH:mm")}
            ticks={hourlyTimestamps}
            interval={0}
            axisLine={false}
            scale="band"
            tick={{
              fontSize: 12,
              fontFamily: "Work Sans",
              fontWeight: 400,
              fill: "var(--grey-grey-600, #939393)",
              style: { lineHeight: "150%", letterSpacing: "0.12px" },
              textAnchor: "middle"
            }}
          />

          <YAxis
            tickFormatter={(tick) => `${(tick * 100).toFixed(0)}%`}
            tickCount={2}
            axisLine={false}
            ticks={[0, 1]}
            domain={[0, 1.25]}
            tick={{
              fontSize: 12,
              fontFamily: "Work Sans",
              fontWeight: 400,
              fill: "var(--grey-grey-600, #939393)",
              style: { lineHeight: "150%", letterSpacing: "0.12px" },
              textAnchor: "middle"
            }}
          />
          <Tooltip
            content={<CustomPercentageBarChartTooltip />}
            cursor={{ fill: "transparent" }}
            position={{ y: -50 }}
          />

          <Legend
            verticalAlign="top"
            iconType="plainline"
            height={42}
            content={
              <PercentageBarChartLegend
                title={props.title}
                subTitle={props.subTitle}
                handleLegendClick={props.handleLegendClick}
              />
            }
          />

          {Object.keys(props.visibleSeries).map((dataKey) => (
            <Bar
              key={dataKey}
              hide={props.visibleSeries[dataKey] === false}
              type="monotone"
              dataKey={dataKey}
              stackId="a"
              fill={props.seriesColor(dataKey)}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
