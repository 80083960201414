import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import { QueryTypes } from "src/constants";

interface BuildQueryPatientEventParams {
  id: string;
  startTime: number;
  endTime: number;
  meta: {
    nextPageCursor: string;
  };
}

interface Event {
  id: string;
  classification: {
    id: string;
    namespace: string;
    category: string;
    enum: string;
  };
  customDetail: null;
  displayName: string;
  duration: {
    startTime: number;
    endTime: number;
    endTimeMax: null;
  };
  ongoing: boolean;
  payload: string;
}

const buildQueryPatientEvent = (
  fetchType: string,
  params: BuildQueryPatientEventParams
): BuildQueryResult => {
  const today = new Date();
  const queryStartDate = new Date(today);
  queryStartDate.setDate(today.getDate() - 89);

  const timestampStartDate: number = Math.floor(
    queryStartDate.getTime() / 1000
  );
  const timestampToday: number = Math.floor(today.getTime() / 1000);

  switch (fetchType) {
    case QueryTypes.GET_MANY_REFERENCE:
      return {
        query: gql`
          query getPatientEvents(
            $id: ID!
            $cursor: Cursor
            $startTime: Float!
            $endTime: Float!
            $includeFilters: [EventClassificationFilter]
            $excludeFilters: [EventClassificationFilter]
            $limit: Int
          ) {
            patient(id: $id) {
              id
              eventList(
                cursor: $cursor
                limit: $limit
                startTime: $startTime
                endTime: $endTime
                includeFilters: $includeFilters
                excludeFilters: $excludeFilters
              ) {
                events {
                  id
                  classification {
                    id
                    namespace
                    category
                    enum
                  }
                  customDetail {
                    displayName
                  }
                  displayName
                  duration {
                    startTime
                    endTime
                    endTimeMax
                  }
                  ongoing
                  payload
                }
                pageInfo {
                  endCursor
                }
              }
            }
          }
        `,
        variables: {
          id: params.id,
          cursor: params.meta.nextPageCursor,
          startTime: timestampStartDate,
          endTime: timestampToday,
          limit: 100
        },
        parseResponse: (response) => {
          const data = response.data.patient.eventList.events.map(
            (event: Event) => {
              /* eslint-disable @typescript-eslint/no-explicit-any */
              const res: any = {
                ...event,
                date: event.duration.startTime
              };

              if (event.classification.category === "medication") {
                // Copied/inspired from js-mono/carrot-web/src/ui/clinicianScreens/Patient/EventFormatting/formatMedicationEventText.js

                // TODO: SW-3711 Move display logic for medication events from dataProvider to a new EventField
                const payload = JSON.parse(event.payload);
                const dosageDetails = [];
                if (payload.dosage) {
                  const unit = `pill${payload.dosage > 1 ? "s" : ""}`;
                  dosageDetails.push(`${payload.dosage} ${unit}`);
                }

                if (payload.dosage_strength) {
                  const { quantity, unit } = payload.dosage_strength;
                  dosageDetails.push(`${quantity} ${unit}`);
                }

                res.dosage = payload.dosage;
                res.dosage_strength = payload.dosage_strength;
                res.failed_dose = payload.failed_dose;
                res.displayName = event.displayName;
                res.medication = event.classification.enum;
                res.medication_id = event.classification.id;

                if (dosageDetails.length) {
                  res.displayNote = `${event.displayName} (${dosageDetails.join(
                    " x "
                  )})`;
                } else {
                  res.displayNote = event.displayName;
                }
              }
              return res;
            }
          );
          return {
            data: data,
            total: response.data.patient.eventList.events.length,
            endCursor: response.data.patient.eventList.pageInfo.endCursor
          };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};
export default buildQueryPatientEvent;
