import * as React from "react";

import {
  Link,
  Logout,
  MenuItemLink,
  UserMenu,
  useGetIdentity
} from "react-admin";

import { AppBar, AppBarProps, ListItemText, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useFlags } from "../clients/launchDarkly";
import { runeTheme } from "../components/common/RuneTheme";
import runeLogo from "../shared/Logo-StriveStudy2.svg";
import { ReactComponent as KeyIcon } from "../shared/icon/key.svg";
import ExitIcon from "@mui/icons-material/PowerSettingsNew";

const NavigationMenu = styled("div")({
  display: "flex",
  width: "100vw",
  height: "73px",
  alignItems: "center",
  justifyContent: "space-between",
  background: runeTheme.palette.primary.main
});

const NavigationMenuLogo = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  padding: "0px",
  width: "186px",
  height: "48px",
  left: "32px",
  top: "calc(50% - 48px/2)",
  marginLeft: "56px"
});

const NavigationMenuResourceItemContainer = styled("div")({
  display: "flex",
  gap: "32px"
});

const NavigationMenuResourceItem = styled("div")({
  /* text */

  width: "93px",
  height: "28px",

  /* Menu heading selected */

  fontFamily: "'Work Sans'",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "24px",
  lineHeight: "48px",
  textAlign: "center",
  letterSpacing: "-0.03em",
  textTransform: "capitalize",
  color: runeTheme.palette.background.light
});

const NavigationMenuLinkItemContainer = styled("div")({
  display: "flex",
  padding: "0px 20px",
  gap: "12px",
  color: runeTheme.palette.background.light
});

const NavigationMenuLinkItem = styled("div")({
  padding: "8px",
  color: runeTheme.palette.background.light
});

const useStyles = makeStyles({
  resourceMenuItemLink: {
    /* Menu heading selected */

    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "150%",
    textAlign: "center",
    letterSpacing: "0.02em",
    textTransform: "capitalize",
    color: "white"
  },
  linkMenuItemLink: {
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "140%",
    /* or 22px */

    letterSpacing: "-0.03em",
    textDecorationLine: "underline",
    color: runeTheme.palette.background.light
  }
});

const StudyHeader = (props: AppBarProps) => {
  const classes = useStyles();
  const { data: user } = useGetIdentity();

  const isAdmin =
    user?.defaultMembership?.role.admin ||
    user?.defaultMembership?.role.superAdmin;

  const { adminNavItemVisible } = useFlags();
  return (
    <AppBar {...props} sx={{ position: "relative" }}>
      <NavigationMenu>
        <NavigationMenuResourceItemContainer>
          <NavigationMenuLogo>
            <Link to="/">
              <img
                src={runeLogo}
                alt="StriveStudy"
                style={{ display: "block" }}
              />
            </Link>
          </NavigationMenuLogo>

          <NavigationMenuResourceItem>
            <Link
              to="/Project"
              className={classes.resourceMenuItemLink}
              style={{ color: "white" }}
            >
              Projects
            </Link>
          </NavigationMenuResourceItem>

          {adminNavItemVisible && isAdmin && (
            <NavigationMenuResourceItem>
              <Link
                to="/Org"
                className={classes.resourceMenuItemLink}
                style={{ color: "white" }}
              >
                Administration
              </Link>
            </NavigationMenuResourceItem>
          )}
        </NavigationMenuResourceItemContainer>
        <NavigationMenuLinkItemContainer>
          <NavigationMenuLinkItem>
            <Link
              to="/help"
              className={classes.linkMenuItemLink}
              style={{ color: "white" }}
            >
              Help
            </Link>
          </NavigationMenuLinkItem>
          <NavigationMenuLinkItem>
            <Link
              to="/documentation"
              className={classes.linkMenuItemLink}
              style={{ color: "white" }}
            >
              Documentation
            </Link>
          </NavigationMenuLinkItem>
          <NavigationMenuLinkItem>
            <Link
              to="/contact"
              className={classes.linkMenuItemLink}
              style={{ color: "white" }}
            >
              Contact Rune Labs
            </Link>
          </NavigationMenuLinkItem>
          <UserMenu>
            <MenuItemLink
              to="/UserAccessToken"
              primaryText={<ListItemText primary="Access Tokens" />}
              leftIcon={
                <KeyIcon
                  style={{ width: "24px", height: "24px", minWidth: "40px" }}
                />
              }
            />
            <Logout
              icon={<ExitIcon fontSize="small" style={{ minWidth: "40px" }} />}
            />
          </UserMenu>
        </NavigationMenuLinkItemContainer>
      </NavigationMenu>
    </AppBar>
  );
};

// Helper func to grab index from end of URL path
export const getTabIndex = (path: string) => {
  const parts = path.split("/");
  const index = parts[parts.length - 1];
  return index;
};

export default StudyHeader;
