import * as React from "react";
import { useState } from "react";

import {
  Button,
  Datagrid,
  TextField,
  ReferenceManyField,
  useRecordContext,
  useCreatePath,
  BulkDeleteButton,
  BulkDeleteButtonProps,
  TabbedShowLayout,
  Tab,
  TabbedShowLayoutTabs,
  useRedirect,
  useGetIdentity,
  useDataProvider,
  useRefresh
} from "react-admin";
import { Switch, Typography, styled } from "@mui/material";
import PersonOffIcon from "@mui/icons-material/PersonOff";

import { useFlags } from "../../../../clients/launchDarkly";
import { runeTheme } from "../../../common/RuneTheme";
import { Project } from "../../../../types";
import DataAvailability from "./DataAvailability";
import AggregatedMetrics from "./AggregatedMetrics";

import { RuneDateField } from "../../../fields/RuneDateField";
import { RuneDurationField } from "../../../fields/RuneDurationField";

import { StudyDatagridHeader } from "../../../common/StudyDataGrid/StudyDatagridHeader";
import TertiaryActionButton from "../../../common/buttons/TertiaryActionButton";
import { AddToCohortButton } from "./AddToCohortButton";
import EditCohortDialog from "../../cohort/CohortEdit";
import iconEdit from "../../../../shared/icon/edit.svg";
import { NoResourceMessage } from "../../../common/NoResourceMessage";
import { DiagnosticField } from "src/components/fields/DiagnosticField";
import { DataAvailabilitySummaryField } from "src/components/fields/DataAvailabilitySummaryField";
import { TimeInterval } from "src/constants";
import {
  ProjectDisplayConfig,
  ProjectDisplayConfigurationDialog
} from "./ProjectDisplayConfigurationDialog";
import { DataRecencyField } from "src/components/fields/DataRecencyField";
import DataAvailabilityHeatmapCalendarField from "src/components/fields/DataAvailabilityHeatmapCalendarField";
import ProjectPatientUpdateDialog from "../../projectpatient/ProjectPatientUpdate";

const NoPatientMessage = () => (
  <NoResourceMessage>No patients.</NoResourceMessage>
);

const ProjectConfiguration = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: "24px",
  alignItems: "center",
  width: "100%"
});

const TertiaryActions = styled("div")({
  alignItems: "center",

  display: "flex",
  padding: "16px",
  gap: "4px",
  height: "19px",
  margin: "8px"
});

const EditProjectButton = () => {
  const project = useRecordContext<Project>();
  const createPath = useCreatePath();
  return (
    <TertiaryActionButton
      link={createPath({ type: "edit", resource: "Project", id: project.id })}
      state={{
        record: {
          project_id: project.id,
          project_title: project.title,
          status: "UNKNOWN_PATIENT"
        }
      }}
      icon={iconEdit}
      label="Edit Project"
    />
  );
};

const RuneBulkDeleteButton = (props: BulkDeleteButtonProps) => {
  return (
    <BulkDeleteButton
      sx={{
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "22px",
        letterSpacing: "-0.03em",
        textDecorationLine: "underline",
        color: runeTheme.palette.primary.main,
        textTransform: "none",
        alignItems: "center"
      }}
      label="Remove Patients"
      confirmContent="Are you sure you want to remove the selected patients?"
      mutationMode="pessimistic"
      icon={<PersonOffIcon />}
      {...props}
    />
  );
};

const ProjectPatientActionButtons = (props: {
  isCohort?: boolean;
  cohortId?: string;
}) => {
  const project = useRecordContext<Project>();
  const { isCohort, cohortId } = props;
  const cohorts = project?.cohortList?.cohorts
    ? project.cohortList.cohorts
    : [];
  const { cohortTabsVisible } = useFlags();

  return (
    <>
      {!isCohort && (
        <>
          {cohortTabsVisible && cohorts.length > 0 && (
            <AddToCohortButton
              id={"addPatientsToCohort"}
              label={"Add patients to cohort"}
              menuItems={cohorts}
            />
          )}
          <RuneBulkDeleteButton
            confirmTitle="Remove Patients from Project"
            mutationOptions={{ meta: { projectId: project.id } }}
          />
        </>
      )}
      {cohortTabsVisible && isCohort && (
        <RuneBulkDeleteButton
          confirmTitle="Remove Patients from Cohort"
          mutationOptions={{ meta: { cohortId: cohortId } }}
        />
      )}
    </>
  );
};

const ShowPIIToggle = (props: {
  isDisplayPIIEnabled: boolean;
  setDisplayPIIEnabled: (isDisplayPIIEnabled: boolean) => boolean;
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setDisplayPIIEnabled(event.target.checked);
  };

  return (
    <>
      <Switch
        color="primary"
        value={props.isDisplayPIIEnabled}
        onChange={handleChange}
      />

      <Typography
        sx={{
          fontSize: "16px",
          lineHeight: "19px",
          letterSpacing: "-0.03em",
          textTransform: "capitalize",
          fontWeight: "600",
          color: runeTheme.palette.button.tertiary.text
        }}
      >
        Display PII
      </Typography>
    </>
  );
};

// https://runelabs.atlassian.net/browse/SW-2470
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const PatientListHeader = (props: any) => {
  const {
    dataAvailabilityValue,
    setDataAvailabilityValue,
    qcMetricSelectedIndex,
    setQcMetricSelectedIndex,
    metrics,
    isDisplayPIIEnabled,
    setDisplayPIIEnabled,
    projectDisplayConfig,
    setProjectDisplayConfig,
    isCohort,
    cohortId,
    dataCategoryCounts,
    setQcMetricSelectedCategory
  } = props;

  const { data: user } = useGetIdentity();
  const { enablePiiToggle, patientListV2 } = useFlags();
  const canSeePII = user?.defaultMembership?.role.canSeePHI;

  const canConfigureProjectDisplay =
    patientListV2 &&
    (user?.defaultMembership?.role.admin ||
      user?.defaultMembership?.role.superAdmin);

  return (
    <div
      style={{
        background: "#DBEEFB",
        borderRadius: "24px 24px 0px 0px"
      }}
    >
      <ProjectConfiguration>
        {!patientListV2 ? (
          <DataAvailability
            dataAvailabilityValue={dataAvailabilityValue}
            setDataAvailabilityValue={setDataAvailabilityValue}
          />
        ) : (
          <div></div>
        )}
        <TertiaryActions>
          {enablePiiToggle && canSeePII && (
            <ShowPIIToggle
              isDisplayPIIEnabled={isDisplayPIIEnabled}
              setDisplayPIIEnabled={setDisplayPIIEnabled}
            />
          )}
          {isCohort ? (
            <EditCohortDialog cohortId={cohortId} />
          ) : (
            <EditProjectButton />
          )}
          {canConfigureProjectDisplay && (
            <ProjectDisplayConfigurationDialog
              projectDisplayConfig={projectDisplayConfig}
              setProjectDisplayConfig={setProjectDisplayConfig}
            />
          )}
        </TertiaryActions>
      </ProjectConfiguration>
      {!patientListV2 ? (
        <AggregatedMetrics
          qcMetricSelectedIndex={qcMetricSelectedIndex}
          setQcMetricSelectedIndex={setQcMetricSelectedIndex}
          metrics={metrics}
        />
      ) : (
        <AggregatedMetrics
          qcMetricSelectedIndex={qcMetricSelectedIndex}
          setQcMetricSelectedIndex={setQcMetricSelectedIndex}
          projectDisplayConfig={projectDisplayConfig}
          dataCategoryCounts={dataCategoryCounts}
          setQcMetricSelectedCategory={setQcMetricSelectedCategory}
        />
      )}
    </div>
  );
};

const PatientExpandView = (props: {
  projectDisplayConfig: ProjectDisplayConfig;
  dataAvailabilityValue: string;
  project: Project;
}) => {
  const { projectDisplayConfig, dataAvailabilityValue, project } = props;
  return (
    <div>
      <DataAvailabilityHeatmapCalendarField
        projectDisplayConfig={projectDisplayConfig}
        dataAvailability={dataAvailabilityValue}
        projectStart={project.startedAt}
      />
    </div>
  );
};

// https://runelabs.atlassian.net/browse/SW-2470
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const PatientList = (props: any) => {
  const {
    dataAvailabilityValue,
    qcMetricSelectedIndex,
    groupedLabels,
    projectDisplayConfig,
    isCohort,
    cohortId,
    displayOnboardedDate,
    displayDataAvailabilityV1,
    displayDataAvailabilityV2,
    metricCategories,
    displayDiagnosticInfo,
    qcMetricSelectedCategory
  } = props;

  const project = useRecordContext<Project>();

  const { data: user } = useGetIdentity();
  const isAdmin =
    user?.defaultMembership?.role.admin ||
    user?.defaultMembership?.role.superAdmin;

  const dateOptions = { year: "numeric", month: "short", day: "numeric" };
  const { enablePiiToggle, patientListV2, referralCode } = useFlags();

  const filter = {
    dataAvailability: dataAvailabilityValue,
    qcMetric: qcMetricSelectedIndex,
    cohortId: cohortId,
    projectStartDate: project.startedAt,

    // Passing feature flag as filters to the data provider
    // Unfortunately, ReferenceManyField does not provide an easy way to pass params to the data provider
    enablePiiToggle: enablePiiToggle,
    patientListV2: patientListV2,
    metricCategories: metricCategories,
    qcMetricCategory: qcMetricSelectedCategory
  };
  if (isCohort && cohortId) filter.cohortId = cohortId;

  return (
    <ReferenceManyField
      label="Patients"
      reference={isCohort ? "CohortPatient" : "ProjectPatient"}
      target={isCohort ? "cohort_id" : "project_id"}
      filter={filter}
    >
      <Datagrid
        empty={<NoPatientMessage />}
        header={<StudyDatagridHeader groupedLabels={groupedLabels} />}
        expand={
          patientListV2 ? (
            <PatientExpandView
              projectDisplayConfig={projectDisplayConfig}
              dataAvailabilityValue={dataAvailabilityValue}
              project={project}
            />
          ) : undefined
        }
        bulkActionButtons={
          <ProjectPatientActionButtons
            isCohort={isCohort}
            cohortId={cohortId}
          />
        }
      >
        <CodeNameNameRedirectTextField source="codeName" />

        {referralCode && <TextField source="appMode" />}

        {props.isDisplayPIIEnabled && (
          <TextField source="realName" label="Full name" />
        )}

        {props.isDisplayPIIEnabled && (
          <TextField source="email" label="email" />
        )}

        {displayOnboardedDate && (
          <RuneDateField
            source="createdAt"
            label="Onboarded since"
            dateFmtOptions={dateOptions}
          />
        )}

        {!patientListV2 && (
          <RuneDurationField
            source="APPLEWATCH_SYMPTOM@AVG_PER_DAY"
            label="Avg/Day"
            inputUnit="HOUR"
            outputFormat="00h 00m"
          />
        )}
        {!patientListV2 && (
          <RuneDurationField
            source="APPLEWATCH_SYMPTOM@TOTAL_HOURS"
            label="Total"
            inputUnit="HOUR"
            outputFormat="00h 00m"
          />
        )}
        {!patientListV2 && (
          <RuneDateField
            showTime={true}
            source="APPLEWATCH_SYMPTOM@LATEST_DATA"
            label="Latest Data"
            dateFmtOptions={dateOptions}
          />
        )}
        {!patientListV2 && (
          <RuneDurationField
            source="APPLEWATCH_HEART_RATE@TOTAL_HOURS"
            label="Total"
            inputUnit="HOUR"
            outputFormat="00h 00m"
          />
        )}
        {!patientListV2 && (
          <RuneDateField
            showTime={true}
            source="APPLEWATCH_HEART_RATE@LATEST_DATA"
            label="Latest Data"
            dateFmtOptions={dateOptions}
          />
        )}
        {displayDataAvailabilityV1 && (
          <DataAvailabilitySummaryField
            source="mm4pd_availability"
            dataAvailability={dataAvailabilityValue}
            projectStart={project.startedAt}
            label="MM4PD & HealthKit"
          />
        )}

        {displayDataAvailabilityV2 &&
          projectDisplayConfig.dataTypes.tremor_dyskinesia.enabled && (
            <DataRecencyField
              source="TREMOR_DYSKINESIA"
              label="Tremor & Dyskinesia"
              category="tremor_dyskinesia"
            />
          )}
        {displayDataAvailabilityV2 &&
          projectDisplayConfig.dataTypes.mobility.enabled && (
            <DataRecencyField
              source="MOBILITY"
              label="Mobility"
              category="mobility"
            />
          )}
        {displayDataAvailabilityV2 &&
          projectDisplayConfig.dataTypes.sleep.enabled && (
            <DataRecencyField source="SLEEP" label="Sleep" category="sleep" />
          )}
        {displayDiagnosticInfo && (
          <DiagnosticField source="diagnostic_info" label="Diagnostic Info" />
        )}

        {isAdmin && (
          <ProjectPatientUpdateDialog projectId={project.id.toString()} />
        )}
      </Datagrid>
    </ReferenceManyField>
  );
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const CodeNameNameRedirectTextField = (props: any) => {
  const record = useRecordContext();
  const redirect = useRedirect();

  return (
    <TextField
      source="codeName"
      sx={{
        textDecoration: "underline",
        color: runeTheme.palette.button.tertiary.text
      }}
      onClick={() => {
        redirect("show", "Patient", record.id);
      }}
    />
  );
};

CodeNameNameRedirectTextField.defaultProps = { label: "Code Name" };

const ProjectTabs = () => {
  const project = useRecordContext<Project>();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const redirect = useRedirect();
  let cohorts = project?.cohortList?.cohorts;
  cohorts = cohorts ? cohorts : [];

  const { patientListV2, advancedMonitoring } = useFlags();

  const { data: user } = useGetIdentity();
  const isAdmin =
    user?.defaultMembership?.role.admin ||
    user?.defaultMembership?.role.superAdmin;

  let displayOnboardedDate = false;
  let displayDataAvailabilityV1 = false;
  let displayDiagnosticInfo = false;
  let displayDataAvailabilityV2 = false;
  let expandedPatientViewEnabled = false;
  const metricCategories: string[] = [];

  const [dataAvailabilityValue, setDataAvailabilityValue] = useState(
    TimeInterval.NINETY_DAYS
  );

  const [qcMetricSelectedIndex, setQcMetricSelectedIndex] = useState(0);
  const [qcMetricSelectedCategory, setQcMetricSelectedCategory] =
    useState("ALL_PATIENTS");

  const [isDisplayPIIEnabled, setDisplayPIIEnabled] = useState(false);

  const defaultConfig = {
    dataTypes: {
      tremor_dyskinesia: { enabled: true, threshold: 6 },
      mobility: { enabled: true, threshold: 0 },
      sleep: { enabled: true, threshold: 1 },
      medication: { enabled: false, threshold: 0 }
    },
    visualization: {
      highlightMissingData: true
    }
  };

  const parsedConfig = project.config
    ? JSON.parse(project.config)["ui_config"]
    : {};
  const projectDisplayConfigValue = Object.keys(parsedConfig).length
    ? parsedConfig
    : defaultConfig;

  const [projectDisplayConfig, setProjectDisplayConfigState] =
    useState<ProjectDisplayConfig>(projectDisplayConfigValue);

  // Custom setter function that also updates the backend
  const setProjectDisplayConfig = async (newConfig: ProjectDisplayConfig) => {
    setProjectDisplayConfigState(newConfig);
    try {
      await dataProvider.update("Project", {
        id: project.id,
        data: { id: project.id, config: newConfig },
        previousData: project
      });
      refresh();
      redirect("show", "Project", project.id, { forceRefresh: true });
      // Handle successful update, e.g., show notification
    } catch (error) {
      // Handle error, e.g., show error notification
      console.error("Failed to update project config:", error);
    }
  };

  if (patientListV2) {
    displayOnboardedDate = true;
    displayDiagnosticInfo = false;
    displayDataAvailabilityV2 = true;
    expandedPatientViewEnabled = true;

    if (projectDisplayConfig.dataTypes.tremor_dyskinesia.enabled)
      metricCategories.push("TREMOR_DYSKINESIA");
    if (projectDisplayConfig.dataTypes.mobility.enabled)
      metricCategories.push("MOBILITY");
    if (projectDisplayConfig.dataTypes.sleep.enabled)
      metricCategories.push("SLEEP");
  } else {
    // For original patient list, we still use the advancedMonitoring flag
    if (project.id in advancedMonitoring) {
      displayOnboardedDate =
        advancedMonitoring[project.id].displayOnboardedDate;
      displayDataAvailabilityV1 =
        advancedMonitoring[project.id].displayDataAvailability;
      displayDiagnosticInfo =
        advancedMonitoring[project.id].displayDiagnosticInfo;
    }
  }

  console.log("ProjectTabs:project", project);

  const { cohortTabsVisible } = useFlags();
  const groupedLabels = [
    {
      groupName: "",
      key: "group-identification",
      subLabels: [
        { name: "Code Name", key: "code-name", sortable: true },
        isDisplayPIIEnabled && {
          name: "Full name",
          key: "full-name",
          sortable: true
        },
        isDisplayPIIEnabled && { name: "email", key: "email", sortable: true },
        displayOnboardedDate && {
          name: "Onboarded since",
          key: "onboarded-since",
          sortable: true
        }
      ].filter(Boolean)
    },

    !patientListV2 && {
      groupName: "Tremor & Dyskinesia",
      key: "group-tremor-dyskinesia",
      subLabels: [
        {
          name: "Avg/Day",
          key: "avg-day",
          source: "APPLEWATCH_SYMPTOM@AVG_PER_DAY"
        },
        {
          name: "Total",
          source: "APPLEWATCH_SYMPTOM@TOTAL_HOURS"
        },
        {
          name: "Latest Data",
          key: "latest-data",
          source: "APPLEWATCH_SYMPTOM@LATEST_DATA"
        }
      ]
    },
    !patientListV2 && {
      groupName: "Health",
      key: "group-health",
      subLabels: [
        {
          name: "Total",
          source: "APPLEWATCH_HEART_RATE@TOTAL_HOURS"
        },
        {
          name: "Latest Data",
          key: "latest-data",
          source: "APPLEWATCH_HEART_RATE@LATEST_DATA"
        }
      ]
    },

    (displayDataAvailabilityV1 ||
      displayDataAvailabilityV2 ||
      displayDiagnosticInfo) && {
      groupName: "Data Availability",
      key: "group-data-availability",
      subLabels: [
        displayDataAvailabilityV1 && {
          name: "MM4PD & HealthKit",
          key: "mm4pd-healthkit",
          source: "mm4pd_availability",
          sortable: false
        },
        displayDataAvailabilityV2 &&
          projectDisplayConfig.dataTypes.tremor_dyskinesia.enabled && {
            name: "Tremor & Dyskinesia",
            source: "TREMOR_DYSKINESIA",
            key: "tremor-dyskinesia",
            sortable: false
          },
        displayDataAvailabilityV2 &&
          projectDisplayConfig.dataTypes.mobility.enabled && {
            name: "Mobility",
            key: "mobility",
            sortable: false
          },
        displayDataAvailabilityV2 &&
          projectDisplayConfig.dataTypes.sleep.enabled && {
            name: "Sleep",
            key: "sleep",
            sortable: false
          },
        displayDiagnosticInfo && {
          name: "Diagnostic",
          key: "diagnostic",
          source: "diagnostic_info",
          sortable: false
        }
      ].filter(Boolean)
    }
  ].filter(Boolean);

  return (
    <TabbedShowLayout
      sx={{
        background: runeTheme.palette.background.default,
        overflowX: "scroll"
      }}
      tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />}
    >
      <Tab label="All" sx={{ textTransform: "capitalize" }}>
        <PatientListHeader
          dataAvailabilityValue={dataAvailabilityValue}
          setDataAvailabilityValue={setDataAvailabilityValue}
          qcMetricSelectedIndex={qcMetricSelectedIndex}
          setQcMetricSelectedIndex={setQcMetricSelectedIndex}
          metrics={project?.projectPatientList?.aggregatedMetrics}
          isDisplayPIIEnabled={isDisplayPIIEnabled}
          setDisplayPIIEnabled={setDisplayPIIEnabled}
          projectDisplayConfig={projectDisplayConfig}
          setProjectDisplayConfig={setProjectDisplayConfig}
          dataCategoryCounts={project?.dataCategoryCounts}
          setQcMetricSelectedCategory={setQcMetricSelectedCategory}
        />
        <PatientList
          dataAvailabilityValue={dataAvailabilityValue}
          qcMetricSelectedIndex={qcMetricSelectedIndex}
          groupedLabels={groupedLabels}
          isDisplayPIIEnabled={isDisplayPIIEnabled}
          projectDisplayConfig={projectDisplayConfig}
          metricCategories={metricCategories}
          displayOnboardedDate={displayOnboardedDate}
          displayDataAvailabilityV1={displayDataAvailabilityV1}
          displayDataAvailabilityV2={displayDataAvailabilityV2}
          displayDiagnosticInfo={displayDiagnosticInfo}
          qcMetricSelectedCategory={qcMetricSelectedCategory}
        />
      </Tab>
      {cohortTabsVisible &&
        // https://runelabs.atlassian.net/browse/SW-2470
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        cohorts.map((value: any) => (
          <Tab
            label={value.title}
            key={value.id}
            sx={{ textTransform: "capitalize" }}
          >
            <PatientListHeader
              dataAvailabilityValue={dataAvailabilityValue}
              setDataAvailabilityValue={setDataAvailabilityValue}
              qcMetricSelectedIndex={qcMetricSelectedIndex}
              setQcMetricSelectedIndex={setQcMetricSelectedIndex}
              metrics={value.cohortPatientList.aggregatedMetrics}
              isDisplayPIIEnabled={isDisplayPIIEnabled}
              setDisplayPIIEnabled={setDisplayPIIEnabled}
              projectDisplayConfig={projectDisplayConfig}
              setProjectDisplayConfig={setProjectDisplayConfig}
              isCohort
              cohortId={value.id}
            />
            <PatientList
              dataAvailabilityValue={dataAvailabilityValue}
              qcMetricSelectedIndex={qcMetricSelectedIndex}
              groupedLabels={groupedLabels}
              isDisplayPIIEnabled={isDisplayPIIEnabled}
              projectDisplayConfig={projectDisplayConfig}
              metricCategories={metricCategories}
              displayOnboardedDate={displayOnboardedDate}
              displayDataAvailabilityV1={displayDataAvailabilityV1}
              displayDataAvailabilityV2={displayDataAvailabilityV2}
              displayDiagnosticInfo={displayDiagnosticInfo}
              qcMetricSelectedCategory={qcMetricSelectedCategory}
              isCohort
              cohortId={value.id}
            />
          </Tab>
        ))}
    </TabbedShowLayout>
  );
};

export default ProjectTabs;
