import { useRecordContext } from "react-admin";
import { styled, Tooltip } from "@mui/material";
import { runeTheme } from "../../../common/RuneTheme";

import { TimeInterval, MILLI_SEC_PER_DAY } from "../../../../constants";

import mixpanel from "mixpanel-browser";

const DataAvailabilityLabel = styled("div")({
  height: "16px",
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "16px",
  letterSpacing: "0.1em",
  textTransform: "uppercase",
  color: "#000000"
});

const DataAvailabilityContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "16px 0px",
  gap: "10px"
});

const DataAvailabilityOptionContainer = styled("div")({
  cursor: "pointer",
  boxSizing: "border-box",
  display: "flex",
  padding: "8px 10px",
  gap: "8px",
  height: "30px",
  borderRadius: "24px",

  border: "1px solid",
  borderColor: runeTheme.palette.primary.main,
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "11px",
  /* identical to box height */

  letterSpacing: "-0.03em",

  /* Primary Color */

  color: runeTheme.palette.primary.main,
  "&.selected": {
    background: runeTheme.palette.secondary.main,
    color: runeTheme.palette.background.light
  },

  "&.disabled": {
    border: "1px solid",
    borderColor: runeTheme.palette.disabled,
    color: runeTheme.palette.disabled,
    cursor: "auto"
  }
});

interface DataAvailabilityOptionProps {
  onClick: () => void;
  tooltip: string;
  disabled: boolean;
  isSelected: boolean;
  label: string;
}

const DataAvailabilityOption = (props: DataAvailabilityOptionProps) => {
  return (
    <Tooltip title={props.tooltip} arrow>
      <DataAvailabilityOptionContainer
        onClick={props.disabled ? undefined : props.onClick}
        className={
          (props.isSelected ? "selected" : "") +
          " " +
          (props.disabled ? "disabled" : "")
        }
      >
        {props.label}
      </DataAvailabilityOptionContainer>
    </Tooltip>
  );
};

const DataAvailability = (props: {
  dataAvailabilityValue: string;
  setDataAvailabilityValue: (arg0: string) => void;
}) => {
  const record = useRecordContext();
  const startDate = new Date(record.startedAt);

  const handleDataAvailabilitySelection = (value: string) => () => {
    mixpanel.track("DataAvailabilitySelection", { value: value });
    props.setDataAvailabilityValue(value);
  };

  const sinceProjectStart: number = Math.round(
    (Date.now() - startDate.getTime()) / MILLI_SEC_PER_DAY
  );

  return (
    <DataAvailabilityContainer>
      <DataAvailabilityLabel>Data Availability</DataAvailabilityLabel>
      <DataAvailabilityOption
        onClick={handleDataAvailabilitySelection(TimeInterval.FOURTEEN_DAYS)}
        disabled={sinceProjectStart < 14}
        isSelected={props.dataAvailabilityValue === TimeInterval.FOURTEEN_DAYS}
        tooltip={"Display total hours of data collected for the last 14 days"}
        label={"2w"}
      />
      <DataAvailabilityOption
        onClick={handleDataAvailabilitySelection(TimeInterval.NINETY_DAYS)}
        disabled={sinceProjectStart < 90}
        isSelected={props.dataAvailabilityValue === TimeInterval.NINETY_DAYS}
        tooltip={"Display total hours of data collected for the last 90 days"}
        label={"3m"}
      />
      <DataAvailabilityOption
        onClick={handleDataAvailabilitySelection(TimeInterval.PROJECT_ALL)}
        disabled={false}
        isSelected={props.dataAvailabilityValue === TimeInterval.PROJECT_ALL}
        tooltip={
          "Display total hours of data collected since the beginning of the project"
        }
        label={"Since Project Start (" + sinceProjectStart + "d)"}
      />
    </DataAvailabilityContainer>
  );
};

export default DataAvailability;
