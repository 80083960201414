import React from "react";
import { useState } from "react";

import {
  email,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  useCreate,
  useRedirect,
  useNotify,
  Button,
  Toolbar,
  RaRecord
} from "react-admin";

import { styled, Grid, Typography } from "@mui/material";
import mixpanel from "mixpanel-browser";
import { v4 as uuidv4 } from "uuid";

import iconPerson from "../../../shared/icon/person.svg";
import iconRemove from "../../../shared/icon/remove-person.svg";
import AddIcon from "@mui/icons-material/Add";
import SecondaryActionButton from "../../common/buttons/SecondaryActionButton";
import RuneSaveButton from "../../common/buttons/RuneSaveButton";
import { runeTheme } from "../../common/RuneTheme";

import { Role, TabIndex } from "../../../constants";

const Wrapper = styled("div")({
  boxShadow: "none"
});

export const roleDisplayNameChoices = [
  { id: Role.ADMIN, name: Role.ADMIN },
  { id: Role.CLINICIAN, name: Role.CLINICIAN },
  { id: Role.MEMBER, name: Role.MEMBER }
];

export const roleDisplayNameToMutationInputs = (displayName: string) => {
  switch (displayName) {
    case Role.ADMIN:
      return { admin: true, canSeePHI: false };
    case Role.CLINICIAN:
      return { admin: true, canSeePHI: true };
    case Role.MEMBER:
      return { admin: false, canSeePHI: false };
    default:
      throw new Error(`cannot save with unknown role ${displayName}`);
  }
};

interface Member {
  displayName: string;
  email: string;
  id: string;
  role: string;
}

interface InviteAttemptResult {
  email: string;
  success: boolean;
  error?: string;
}

export default function MemberInviteForm(props: {
  handleClickClose: () => void;
  record: RaRecord;
}) {
  mixpanel.track("MemberInviteForm");
  const { handleClickClose, record } = props;

  const [create] = useCreate();
  const redirect = useRedirect();
  const notify = useNotify();

  const InviteMemberSave = async (members: Member[]) => {
    mixpanel.track("InviteMemberSave");
    const orgId = record.id;
    const orgsMembersPath = `/Org/${orgId}/show/${TabIndex.ORG_TAB_MEMBERS}`;

    const promiseResults = members.map((member: Member) => {
      const email = member.email;
      const displayName = member.displayName;

      const { admin, canSeePHI } = roleDisplayNameToMutationInputs(member.role);
      const defaultLoginPortal = "STRIVE_STUDY";

      return create(
        "Member",
        {
          data: {
            orgId,
            email,
            displayName,
            admin,
            canSeePHI,
            defaultLoginPortal
          }
        },
        { returnPromise: true }
      )
        .then(() => {
          return { email: member.email, success: true };
        })
        .catch((error) => {
          return {
            email: member.email,
            success: false,
            error: error.message
          };
        });
    });

    // Wait for results from all mutations
    const results = await Promise.all(promiseResults);

    processResults(results, orgsMembersPath);
  };

  const [members, setMembers] = useState([
    { id: uuidv4(), displayName: "", email: "", role: "" }
  ]);

  const addMember = () => {
    setMembers([
      ...members,
      { id: uuidv4(), displayName: "", email: "", role: "" }
    ]);
  };

  const removeMember = (id: string) => {
    // Can't remove if there's only one set of input fields left
    if (members.length > 1) {
      const newMembers = members.filter((member) => member.id !== id);
      setMembers(newMembers);
    }
  };

  const handleInputChange = (id: string, fieldName: string, value: string) => {
    const newMembers = members.map((member) => {
      if (member.id === id) {
        return { ...member, [fieldName]: value };
      }
      return member;
    });
    setMembers(newMembers);
  };

  // As per the react-admin docs, an array of multiple validator functions needs to be defined outside of JSX,
  // or else it can result in infinite re-rendering.
  const validateRequiredEmail = [required(), email()];

  const renderFields = () => {
    return (
      <>
        {members.map((member) => (
          <Grid container spacing={2} key={member.id}>
            <Grid item xs={4}>
              <TextInput
                variant="outlined"
                source={`members[${member.id}].displayName`}
                value={member.displayName}
                onChange={(e) =>
                  handleInputChange(member.id, "displayName", e.target.value)
                }
                label="Display Name"
                fullWidth={true}
                validate={required()}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                variant="outlined"
                source={`members[${member.id}].email`}
                value={member.email}
                onChange={(e) =>
                  handleInputChange(member.id, "email", e.target.value)
                }
                label="Email"
                fullWidth={true}
                validate={validateRequiredEmail}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectInput
                variant="outlined"
                source={`members[${member.id}].role.displayName`}
                value={member.displayName}
                onChange={(e) =>
                  handleInputChange(member.id, "role", e.target.value)
                }
                label="Role"
                choices={roleDisplayNameChoices}
                isRequired
                validate={required()}
              />
              {members.length > 1 && (
                <Button onClick={() => removeMember(member.id)}>
                  <img
                    src={iconRemove}
                    alt="remove member"
                    style={{
                      marginTop: "15px"
                    }}
                  />
                </Button>
              )}
            </Grid>
          </Grid>
        ))}
      </>
    );
  };

  return (
    <Create
      redirect="show"
      component={Wrapper}
      sx={{
        "& .RaCreate-main": {
          boxShadow: "none",
          borderRadius: "8px",
          width: "710px"
        }
      }}
    >
      <SimpleForm
        toolbar={MembershipCreateToolbar({ handleClickClose })}
        onSubmit={() => InviteMemberSave(members)}
        record={record}
        component={Wrapper}
        sx={{ display: "block" }}
      >
        <Typography sx={{ paddingBottom: "20px", width: "680px" }}>
          Invite members to the <b>{record.displayName}</b> organization by
          entering their email address, a display name, and selecting a role.
        </Typography>
        {renderFields()}
        <Button onClick={addMember} label="Add Member">
          <AddIcon />
        </Button>
      </SimpleForm>
    </Create>
  );

  function processResults(
    results: InviteAttemptResult[],
    orgsMembersPath: string
  ) {
    const successEmails = results
      .filter((result) => result.success)
      .map((result) => result.email);

    const errorEmails = results
      .filter((result) => !result.success)
      .map((result) => ({ email: result.email, error: result.error }));

    if (successEmails.length > 0) {
      const successMessage = `Invitation(s) sent successfully to:\n${successEmails.join(
        ", "
      )}`;
      notify(successMessage, { type: "info", multiLine: true });
      redirect(orgsMembersPath);
    }

    if (errorEmails.length > 0) {
      const errorMessages = errorEmails.map(
        (result) => `- ${result.email}: ${result.error}`
      );
      const errorMessage = `Failed to invite members:\n${errorMessages.join(
        "\n"
      )}`;
      notify(errorMessage, { type: "error", multiLine: true });
    }
  }
}

function MembershipCreateToolbar(props: { handleClickClose: () => void }) {
  return (
    <Toolbar
      className="RuneFormBottomToolbar"
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        width: "680px",
        margin: 0,
        background: runeTheme.palette.background.light
      }}
      disableGutters={true}
    >
      <SecondaryActionButton
        label={"Cancel"}
        onClick={props.handleClickClose}
      />
      <RuneSaveButton label="INVITE" icon={iconPerson} />
    </Toolbar>
  );
}
