export const DATE_PICKER_DATA = {
  mobility: {
    classNameSuffix: "Mobility",
    legendText: "MOBILITY"
  },
  sleep: {
    classNameSuffix: "Sleep",
    legendText: "SLEEP"
  },
  tremor_dyskinesia: {
    classNameSuffix: "TremorDyskinesia",
    legendText: "TREMOR/DYSKINESIA"
  }
};
